/* billing.scss */

@import './global-variables.scss';

.billing-page {
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 1.5rem;

    .card {
        h6 {
            margin-bottom: 0.5rem;
        }

        p {
            font-family: var(--main-font-regular);
            color: var(--primary-text-color);
            font-size: var(--normal-font-size);
            margin-top: unset;
            margin-bottom: unset;
            
            &:not(:last-child) {
                margin-bottom: 0.25rem;
            }
        }

        .card-edit-header {
            display: flex;
            justify-content: space-between;

            .edit-icon {
                font-size: var(--small-font-size);
                visibility: hidden;
            }
        }

        &:hover {
            .edit-icon {
                visibility: visible;

                &:hover {
                    color: var(--text-color-placeholder);
                }
            }
        }

        &.subscription-details {
            .subscription-details-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.5rem;

                h6 {
                    margin-bottom: unset;
                }

                p {
                    font-size: var(--small-font-size);
                }
            }

            .subscription-details-content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .module-details {
                    margin-top: 1.25rem;
                }

                .text-button {
                    font-size: var(--normal-font-size);
                    font-family: var(--header-font-medium);
                    color: var(--primary-color);
                    margin-top: 0.5rem;
                    cursor: pointer;
                }

                .monthly-price {
                    display: flex;
                    flex-direction: column;
                    margin-top: -0.8rem;

                    .price-big {
                        font-family: var(--main-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--large-font-size);
                        font-size: 1.125rem;
                        margin-right: 0;
                        margin-left: auto;
                        line-height: 1.125rem;
                    }
                }
            }

            &.pending-subscription {
                border: 1px solid #ffeeba;
                color: #856404;
                background-color: #fff3cd;
        
                h6,
                p,
                .monthly-price .price-big {
                    color: #856404;
                }
            }
        }

        &.cancel-subscription {
            box-shadow: unset;
            border: 1px solid var(--border-color);
            background-color: var(--background-color);

            .text-button {
                font-size: var(--normal-font-size);
                font-family: var(--header-font-medium);
                color: var(--primary-color);
                margin-top: 0.5rem;
                cursor: pointer;
            }
        }

        &.billing-details {
            &:hover {
                background-color: var(--list-hover-color);
                cursor: pointer;
            }

            .field {
                .label {
                    font-size: var(--small-font-size);
                    font-family: var(--main-font-bold);
                }
    
                .value {
                    font-family: var(--main-font-regular);
                    color: var(--primary-text-color);
                    font-size: var(--normal-font-size);
                }

                &:not(:last-child) {
                    margin-bottom: 0.75rem;
                }
            }            
        }

        &.payment-method {
            &:hover {
                background-color: var(--list-hover-color);
                cursor: pointer;
            }
        }

        &.next-invoice {
            .next-invoice-details {
                display: flex;
                flex-direction: space-between;
                align-items: center;

                .price-big {
                    font-family: var(--main-font-regular);
                    color: var(--primary-text-color);
                    font-size: var(--large-font-size);
                    font-size: 1.125rem;
                    margin-top: -1.5rem;
                    margin-right: 0;
                    margin-left: auto;
                    line-height: 1.125rem;
                }
            }
        }
    }
}

.payment-method-form {
    .credit-card-field,
    .ideal-field,
    .sepa-field {
        border: 1px solid var(--border-color);
        border-radius: 0.3rem;
        padding: 0.5rem;
        height: 19px;
    }
}

.wizard.subscription-wizard {
    margin-top: 0;

    .step-content {
        margin-top: 1rem;
    }

    .pending-downgrade-message {
        display: flex;
        flex-direction: column;
        align-items: center;

        .message-box {
            border: 1px solid #ffeeba;
            border-radius: 0.25rem;
            color: #856404;
            background-color: #fff3cd;
            font-family: var(--main-font-regular);
            font-size: var(--normal-font-size);
            padding: 1rem;
            margin-bottom: 1.5rem;
            width: 80%;
    
            h6 {
                color: #856404;
                margin-bottom: 0.25rem;
            }
        }
    }

    .duration-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;

        .switch-button {
            background-color: var(--primary-color);

            &.on {
                background-color: var(--primary-color);
            }
        }
        
        .duration-label {
            font-family: var(--header-font-regular);
            font-size: var(--normal-font-size);
            cursor: pointer;

            &.left-label {
                min-width: 163px;
                text-align: right;
            }

            &.right-label {
                min-width: 270px;
                text-align: center;
            }

            &:hover {
                font-family: var(--header-font-medium);
                color: var(--primary-color);
            }

            &.active {
                font-family: var(--header-font-medium);
                color: var(--primary-color);
            }
        } 
    }

    .package-options {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;

        .package-option {
            background-color: var(--background-color-white);
            border: 1px solid var(--background-color-white);
            margin-top: 1.375rem;
            padding: 1rem;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
            border-radius: 0.25rem;
            position: relative;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                border: 1px solid var(--primary-color);
                background-color: var(--primary-color-background-selected);
            }

            &.best-choice {
                margin-top: 0;
                padding-top: 0.75rem;
            }

            .package-content {
                .best-choice-label {
                    font-family: var(--header-font-medium);
                    color: var(--primary-color);
                    font-size: var(--small-font-size);
                    margin-bottom: 0.75rem;
                }

                .package-label {
                    margin-bottom: 0.75rem;
                }

                .package-explanation {
                    font-family: var(--main-font-light);
                    font-size: var(--normal-font-size);
                    height: 2.375rem;
                    margin-bottom: 1rem;
                }

                .package-features {
                    height: 12rem;

                    .included-label {
                        font-family: var(--main-font-regular);
                        font-size: var(--normal-font-size);
                        margin-bottom: 0.5rem;
                    }

                    .included-feature {
                        font-family: var(--main-font-light);
                        font-size: var(--normal-font-size);
                        margin-bottom: 0.5rem;
                    }
                }

                .package-price {
                    margin-top: 0.25rem;

                    .price-label {
                        font-family: var(--header-font-medium);
                        font-size: var(--h2-font-size);
                        color: var(--primary-color);

                        .currency-icon {
                            font-family: var(--main-font-bold);
                        }
                    }

                    .price-unit-label {
                        margin-top: 0.25rem;
                        font-family: var(--main-font-light);
                        font-size: var(--smaller-font-size);
                    }
                }
            }   
        }
    }

    .seats-selection {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;

        .seats-header h3 {
            font-size: 1.125rem;
            margin-bottom: 0.25rem;
        }

        .seats-description {
            margin: unset;
        }

        .seats-input {
            width: 8rem;
            height: 42px;
        }
    }

    .feature-comparison {
        .feature-comparison-header {
            text-align: center;
            font-family: var(--header-font-medium);
            color: var(--primary-color);
            font-size: var(--normal-font-size);

            .feature-comparison-label {
                cursor: pointer;
            }

            .feature-comparison-toggle-icon {
                margin-left: 0.25rem;
                font-size: var(--normal-font-size);
            }
        }
        
        .comparison-table {
            margin-bottom: 4rem;

            .comparison-section {
                margin-top: 2rem;

                .comparison-header {
                    text-align: center;
                    padding: 0.5rem;
                }

                .comparison-section-table {
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed;

                    th, td {
                        width: 25%;
                        text-align: center;
                        
                    }
        
                    th {
                        border-bottom: 1px solid var(--border-color);
                        padding: 0.5rem;
                        font-size: var(--h6-font-size);
                        font-family: var(--header-font-medium);
                        font-family: var(--main-font-regular);
                        font-size: var(--normal-font-size);
                        font-weight: 400;
                    }
        
                    td {
                        border-bottom: 1px solid var(--border-color);
                        padding: 0.5rem;
                        font-family: var(--main-font-light);
                        font-size: var(--normal-font-size);

                        .info-icon {
                            color: var(--text-color-placeholder);
                            margin-left: 0.25rem;
                        }
                    }
                }   
            }
        }
    }

    .price-summary {
        margin-left: auto;
        margin-right: 1rem;
        text-align: right;
        display: flex;
        align-items: center;
        

        .savings-info {
            font-family: var(--header-font-regular);
            font-size: var(--small-font-size);
            color: var(--text-color-lightgray);
        }

        .savings-info-divider {
            font-family: var(--header-font-regular);
            font-size: var(--small-font-size);
            color: var(--text-color-lightgray);
            margin: 0 0.5rem;
        }

        .total-price {
            font-family: var(--header-font-regular);
            font-size: var(--normal-font-size);
        }
    }

    .billing-details {
        .card {
            h4 {
                margin-bottom: 0.5rem;
            }

            .card-details {
                display: flex;
                justify-content: center;
            }

            .company-fields,
            .address-fields,
            .billing-fields {
                max-width: 580px;
                width: 100%;

                .formfield {
                    margin-bottom: 0.5rem;
                }
            }

            .billing-fields {
                .credit-card-field,
                .ideal-field,
                .sepa-field {
                    border: 1px solid var(--border-color);
                    border-radius: 0.3rem;
                    padding: 0.5rem;
                    height: 19px;
                }
            }
        }
    }

    .subscription-overview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;

        .card {
            h3 {
                margin-bottom: 0.5rem;
            }
    
            p {
                font-family: var(--main-font-regular);
                color: var(--primary-text-color);
                font-size: var(--normal-font-size);
                margin-top: unset;
                margin-bottom: unset;
                
                &:not(:last-child) {
                    margin-bottom: 0.25rem;
                }
            }

            .subscription-details-content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .module-details {
                    margin-top: 1.25rem;
                }

                .recurring-price {
                    display: flex;
                    flex-direction: column;
                    margin-top: -0.8rem;
                    font-family: var(--main-font-regular);
                    color: var(--primary-text-color);
                    font-size: var(--normal-font-size);
                    margin-top: unset;
                    margin-bottom: unset;

                    .price-big {
                        font-family: var(--main-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--large-font-size);
                        font-size: 1.125rem;
                        margin-right: 0;
                        margin-left: auto;
                        line-height: 1.125rem;
                    }
                }
            }

            .payment-details {
                // display: flex;
                // justify-content: space-between;
                // align-items: center;

                .price-detail-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    .accept-conditions-checkbox {
        margin-left: auto;
        margin-right: 1rem;
        text-align: right;
        display: flex;
        align-items: center;
    }
}