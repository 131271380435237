/* kanban.css */

@import './global-variables.scss';

.kanban-page {
    .kanban-navigation {
        margin-bottom: 2.5rem;
        display: flex;
        justify-content: space-between;

        .kanban-totals {
            padding-top: 0.15rem;
            padding-right: 0.5rem;
            font-family: var(--header-font-regular);
            color: var(--primary-text-color);
            font-size: var(--normal-font-size);

            .weighted-value {                
                .weighted-icon {
                    color: var(--secondary-button-text-color);
                    font-size: 0.8rem;
                    margin-top: -0.15rem;
                    padding-right: 0.1rem;
                }
            }

            .currency {
                font-family: var(--main-font-regular);
                font-size: var(--small-font-size);
            }
        }
    }

    .kanban-board {
        display: flex;
        flex-direction: row;

        .loader {
            margin-left: calc(50% - 9px);
            margin-right: auto;
            margin-top: 6px;
            border: 2px solid var(--background-color);
            border-top: 3px solid #bababa;
        }

        .kanban-column {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            min-width: 10rem;
            border-right: 1px solid var(--border-color);

            .column-header {
                padding: 0.75rem;
                border-top: 1px solid var(--border-color);
                border-bottom: 1px solid var(--border-color);
                background-color: var(--background-color-white);

                .stats {
                    padding-top: 0.1rem;
                    font-family: var(--header-font-regular);
                    color: var(--primary-text-color);
                    font-size: var(--small-font-size);

                    .weighted-value {
                        display: none;
                        
                        .weighted-icon {
                            color: var(--secondary-button-text-color);
                            font-size: 0.7rem;
                            padding-right: 0.1rem;
                        }
                    }

                    .currency {
                        font-family: var(--main-font-regular);
                        font-size: var(--smaller-font-size);
                    }
                }

                &:hover {
                    .stats {
                        .total-value {
                            display: none;
                        }

                        .weighted-value {
                            display: inline;
                        }
                    }
                }
            }
        
            .kanban-column-cards {
                flex-grow: 1;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                height: 10rem;

                .kanban-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: var(--background-color-white);
                    cursor: pointer;
                    transition: background 0.2s ease;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
                    font-family: var(--header-font-medium);
                    color: var(--primary-text-color);
                    font-size: var(--normal-font-size);
                    padding: 0.6rem;
                    margin: 0.25rem 0.25rem 0 0.25rem;

                    .card-details {
                        flex: 1;
                        min-width: 0;
                        margin-right: 0.2rem;

                        .subtitle {
                            font-family: var(--header-font-regular);
                            color: var(--primary-text-color);
                            font-size: var(--small-font-size);
                            padding-top: 0.25rem;
                        }
                    }

                    .card-value {
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;
                        white-space: nowrap;
                        font-family: var(--header-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--normal-font-size);
                
                        .currency {
                            display: flex;
                            align-items: center;
                            gap: 0.1rem;
                            font-family: var(--main-font-regular);
                        }
                    }
                }
            }
        }

        .resolution-footer {
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            height: 4rem;
            background-color: var(--background-color);

            .resolution-column {
                flex: 1 1;
                border-top: 1px solid var(--border-color);
                border-right: 1px solid var(--border-color);
                align-content: center;
                text-align: center;
                font-family: var(--header-font-medium);
                color: var(--text-color-white);
                font-size: var(--normal-font-size);

                &.lost {
                    background-color: var(--general-red);
                    border-top: 1px solid var(--general-red);
                    border-right: 1px solid var(--general-red);

                    &:hover {
                        background-color: var(--general-red-hover);
                        border-top: 1px solid var(--general-red-hover);
                        border-right: 1px solid var(--general-red-hover);
                    }
                }

                &.won {
                    background-color: var(--general-green);
                    border-top: 1px solid var(--general-green);
                    border-right: 1px solid var(--general-green);

                    &:hover {
                        background-color: var(--general-green-hover);
                        border-top: 1px solid var(--general-green-hover);
                        border-right: 1px solid var(--general-green-hover);
                    }
                }
            }
        }
    }
}