/* detailpage.css */

@import './global-variables.scss';

.detailpage {
    .detailpage-header {
        justify-content: space-between;

        .header-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .back-button {
                color: var(--text-color-lightgray);
                padding-top: 0.1rem;
                margin-right: 0.5rem;
                font-size: 1.4rem;

                &:hover {
                    color: var(--text-color-placeholder);
                    cursor: pointer;
                }
            }
        
            .live-edit-field {
                .view-mode {
                    p {
                        font-size: var(--h2-font-size);
                        font-family: var(--header-font-regular);
                        padding: 0.45rem 0.3rem;

                        &:hover {
                            cursor: text;
                        }
                    }

                    .edit-icon {
                        font-size: 14px;
                    }
                }

                .edit-mode {
                    padding-left: 0;
                    padding-right: 0;

                    input {
                        background-color: var(--background-color);
                        font-size: var(--h2-font-size);
                        font-family: var(--header-font-regular);
                        color: var(--primary-text-color);
                        padding: 0.45rem 0.3rem;
                        height: unset;
                        border: unset;
                        outline: 1px solid var(--border-color);
                        box-shadow: unset;
                        border-radius: 0.2rem;

                        &:focus {
                            outline: 1px solid var(--secondary-shadow-color);
                        }
                    }
                }
            }
        }
    }

    .status-bar {
        margin-top: 1.75rem;
        height: 1.75rem;

        .status-step {
            color: var(--text-color-white);

            &.active {
                background-color: var(--general-green);

                &::after {
                    border-left-color: var(--general-green);
                }
            }
        }

        &.won {
            .status-step {
                color: var(--general-green);
                background-color: var(--general-green);

                &::after {
                    border-left-color: var(--general-green);
                }
            }
        }

        &.lost {
            .status-step {
                color: var(--text-color-white);
    
                &.active {
                    background-color: var(--general-red);
    
                    &::after {
                        border-left-color: var(--general-red);
                    }
                }
            }
        }
    }

    .detailpage-content {
        display: grid;
        grid-template-columns: 3fr 6fr;
        gap: 1.5rem;
        justify-content: space-between;

        .tabbar {
            padding: 1rem 1rem 0.95rem 1rem;
            display: flex;

            li {
                font-size: var(--h6-font-size);
                font-family: var(--header-font-medium);
                font-weight: 400;

                &:not(:first-child) {
                    padding-left: 1.6rem;
                }
            }
        }

        .list-component {
            .list-button-wrapper {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 1.5rem;
            }

            .container-filled {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .attachment-detail-list {
            .attachment-loader {
                display: flex;
                justify-content: center;
                padding: 1rem;
                align-items: center;

                .loader {
                    border: 2px solid var(--background-color);
                    border-top: 3px solid #bababa;
                }
            }

            .form-alert {
                margin-bottom: 1.5rem;
            }

            .dropzone {
                border: 1px dashed var(--border-color);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: var(--text-color-placeholder);
                padding-right: 0.6rem;
                padding-left: 0.6rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                height: unset;
                margin-bottom: 1.5rem;
            
                &.hover {
                    background-color: var(--list-hover-color);
                }
        
                input[type='file'] {
                    display: none;
                }
            }

            .attachments-form {
                margin-bottom: 1.5rem;
                padding: 1rem;
                background-color: var(--background-color-white);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

                .form-header {
                    .header-item {
                        font-family: var(--main-font-bold);
                        font-size: var(--small-font-size);
                        color: var(--primary-text-color);
                    }
                }

                .attachments-row {
                    display: grid;
                    grid-template-columns: 2fr 1.5fr 1fr;
                    gap: 0.5rem;
                    align-items: center;

                    .p {
                        font-family: var(--main-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--normal-font-size);
                    }
                }

                .attachments-button-row {
                    display: grid;

                    div {
                        justify-self: end;

                        .buttons-right {
                            float: unset;
                            margin-left: auto;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }

            .empty-text {
                p {
                    font-size: var(--small-font-size);
                    color: var(--text-color-lightgray);
                    text-align: center;
                }
            }

            .attachment-group {
                h5 {
                    margin-bottom: 0.5rem;
                }

                .list {
                    &.attachment-list {
                        margin-bottom: 1.5rem;
        
                        tbody {
                            tr {
                                display: grid;
                                grid-template-columns: 1.5fr auto auto 22px 20px;
                            }
        
                            &:not(:last-child) {
                                border-bottom: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}