/* live-edit.css */

.edit-mode input,
.edit-mode select {
    width: 100%;
}

.live-edit-field {
    label {
        font-family: var(--main-font-bold);
        font-size: var(--normal-font-size);
        color: var(--primary-text-color); 
        padding: 0.4rem 0.3rem 0.1rem 0.3rem;
        margin: 0;
        display: inline-block;
    }

    // Base view mode styling
    .view-mode { 
        .view-mode-wrapper {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        p {
            margin: 0;

            &.textarea {
                white-space: pre-wrap;
                word-break: break-word;

                strong {
                    font-family: var(--main-font-bold);
                }

                ul {
                    padding-inline-start: 20px;
                }

                li {
                    list-style-type: unset;
                    list-style-position: inside;

                    /* Change padding of indents */
                    &.ql-indent-1 {
                        padding-left: 1.5rem !important;
                    }

                    &.ql-indent-2 {
                        padding-left: 3rem !important;
                    }

                    &.ql-indent-3 {
                        padding-left: 4.5rem !important;
                    }

                    &.ql-indent-4 {
                        padding-left: 6rem !important;
                    }

                    &.ql-indent-5 {
                        padding-left: 7.5rem !important;
                    }

                    &.ql-indent-6 {
                        padding-left: 9rem !important;
                    }

                    &.ql-indent-7 {
                        padding-left: 10.5rem !important;
                    }

                    &.ql-indent-8 {
                        padding-left: 12rem !important;
                    }
                }
            }
        }

        .p, .hover-text {
            font-family: var(--main-font-regular);
            color: var(--primary-text-color);
            font-size: var(--normal-font-size);
        }

        .p {
            &.coloured-option {
                padding: 0.2rem;
                border-radius: 0.2rem;
                padding: 0.2rem 0.5rem 0.15rem 0.5rem;
                border-radius: 1rem;
                line-height: 1.2rem;
                color: var(--primary-button-text-color);
                font-size: var(--small-font-size);
            }
        }
    }

    // Horizontal alignment styling
    .horizontal-alignment {
        label, .view-mode {
            padding: 0.4rem 0.3rem 0.4rem 0.3rem;
        }
    
        label {
            font-size: var(--small-font-size);
            display: flex;
            align-items: center;
            min-height: 20px;
        }
    
        label.coloured-options-label {
            padding-top: 0.65rem;
        }

        .view-mode {
            .p,
            .view-mode-wrapper {
                padding: 0;
                display: unset;
                text-align: right;
                float: right;

                &.coloured-option {
                    padding: 0.2rem;
                    border-radius: 0.2rem;
                    padding: 0.2rem 0.5rem 0.15rem 0.5rem;
                    border-radius: 1rem;
                    line-height: 1.2rem;
                    color: var(--primary-button-text-color);
                    font-size: var(--small-font-size);
                }
            }
        }
    }

    // Stacked alignment styling
    .stacked-alignment {
        .view-mode {
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }

        .view-mode > span:not(p > span) {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

// Full-width aligment styling
.full-width-alignment {
    .view-mode {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .p {
            display: flex;
            flex-direction: column;
        }
    }
}

.live-edit-field .edit-mode,
.live-edit-field .button-row {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.live-edit-field .alert {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}

.live-edit-field .tooltip {
    left: calc(50% + 0.1rem);
}

.live-edit-field .tooltip-icon {
    font-size: 0.95rem;
    padding-left: 0.3rem;
}

.live-edit-field .edit-mode label {
    padding-left: 0;
    padding-right: 0;
}

.stacked-alignment.editable .view-mode:hover,
.full-width-alignment.editable .view-mode:hover {
    background-color: var(--secondary-color);
    border-radius: 0.3rem;
    cursor: pointer;
}

.full-width-alignment .view-mode .add-item {
    font-family: var(--main-font-bold);
    color: var(--text-color-lightgray);
}

.full-width-alignment.editable .view-mode:hover .add-item {
    color: var(--primary-color);
}

.full-width-alignment .view-mode .item-list .item:not(:last-child) {
    margin-bottom: 0.2rem;
}

.full-width-alignment .view-mode .empty-label,
.stacked-alignment .view-mode .empty-label {
    font-family: var(--main-font-bold);
    color: var(--text-color-lightgray);
    font-size: var(--normal-font-size);
}

.stacked-alignment.editable .view-mode:hover .empty-label {
    color: var(--primary-color);
}

.stacked-alignment .edit-icon,
.full-width-alignment .edit-icon {
    font-family: var(--main-font-regular);
    font-size: var(--normal-font-size);
    visibility: hidden;
    padding-right: 0.2rem;
    color: var(--primary-text-color);
}

.horizontal-alignment .edit-icon {
    visibility: hidden;
    padding-left: 0.2rem;
    color: var(--primary-text-color);
}

.stacked-alignment .view-mode .no-value {
    display: inline-block;
    align-items: center;
    visibility: visible;
    margin-top: -0.05rem;
}

.stacked-alignment .view-mode .no-value .hover-text {
    position: absolute;
    visibility: hidden;
    top: 0.4rem;
    left: 0.3rem;
    font-family: var(--main-font-bold);
    color: var(--primary-color);
}

.horizontal-alignment .view-mode .no-value {
    position: relative;
    width: 100%;
    text-align: right;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.horizontal-alignment .view-mode .no-value .hover-text {
    display: none;
    font-family: var(--main-font-bold);
    color: var(--primary-color);
}

.horizontal-alignment.editable:hover .edit-icon,
.stacked-alignment.editable .view-mode:hover .edit-icon,
.full-width-alignment.editable .view-mode:hover .edit-icon {
    visibility: visible;
}

.stacked-alignment.editable .view-mode:hover .no-value .hover-text {
    visibility: visible;
}

.horizontal-alignment.editable:hover .minus-sign {
    visibility: hidden;
}

.horizontal-alignment.editable:hover .view-mode .no-value .hover-text {
    display: inline-block;
    font-family: var(--main-font-bold);
    color: var(--primary-color);
}

.horizontal-alignment {
    display: grid;
    align-items: start;
    grid-template-columns: 3fr 4fr;
}

.horizontal-alignment:not(.editing) {
    display: flex;
    justify-content: space-between;
}

.horizontal-alignment:not(.editing) > label, 
.horizontal-alignment:not(.editing) > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.horizontal-alignment.editing {
    grid-template-columns: 2fr 5fr;
}

.horizontal-alignment.editing.switchfield {
    grid-template-columns: 5fr 2fr;
}

.detailmodal .multi-columns .horizontal-alignment.editing {
    grid-template-columns: 2fr 4fr;
}

.view-mode .coloured-option-wrapper {
    display: flex;
    gap: 0.3rem;
    float: right;
    align-items: center;
}

.horizontal-alignment .view-mode p:not(:first-child) {
    padding-top: 0.2rem;
}

.horizontal-alignment .view-mode .item-list {
    display: flex;
    flex-direction: column;
}

.horizontal-alignment.editable:not(.editing):hover {
    background-color: var(--secondary-color);
    border-radius: 0.3rem;
    cursor: pointer;
}

.horizontal-alignment.editable:not(.editing):hover label {
    cursor: pointer;
}

.horizontal-alignment .edit-mode {
    display: block;
}

.horizontal-alignment {
    .edit-mode {
        .two-fields {
            display: grid;
            grid-template-columns: 5fr 4fr;
            gap: 0.3rem;
            align-items: start;

            .field-column {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.edit-mode .list-item:not(:last-child),
.edit-mode .list-item:not(:last-child) {
    padding-bottom: 0.3rem;
}

.edit-mode .list-item.draggable {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
}

.horizontal-alignment .edit-mode .list-item .single-field {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.3rem;
    align-items: center;
}

.horizontal-alignment .edit-mode .list-item .two-fields {
    display: grid;
    grid-template-columns: 10fr 7fr auto;
    gap: 0.3rem;
    align-items: center;
}

.stacked-alignment .edit-mode .list-item .single-field {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.3rem;
    align-items: center;
}

.stacked-alignment .edit-mode .list-item .two-fields {
    display: grid;
    grid-template-columns: 6fr 3fr auto;
    gap: 0.3rem;
    align-items: center;
}

.rows-field .rows-header .header-item,
.widget-field .rows-header .header-item  {
    font-family: var(--main-font-regular);
    color: var(--primary-text-color);
    font-size: var(--small-font-size);
}

.rows-header .drag-placeholder {
    width: 18px;
}

.rows-header .delete-placeholder {
    width: 16px;
}

.edit-mode .price-currency {
    display: grid;
    justify-content: space-between;
    gap: 0.3rem;
}

.horizontal-alignment .edit-mode .price-currency {
    grid-template-columns: 8fr 7fr;
}

.stacked-alignment .edit-mode .price-currency {
    grid-template-columns: 2fr 1fr;
}

.price-field {
    display: flex;
    align-items: center;
    position: relative;

    .currency-symbol {
        position: absolute;
        left: 0.75rem;
        pointer-events: none;
        font-family: var(--main-font-regular);
        font-size: var(--normal-font-size);
        color: var(--primary-text-color);
        z-index: 1;
    }

    input {
        padding-left: 1.65rem;
        box-sizing: border-box;
        width: 100%;
    }
}

.percentage-field {
    display: flex;
    align-items: center;
    position: relative;

    .percentage-symbol {
        position: absolute;
        right: 0.75rem;
        pointer-events: none;
        font-family: var(--main-font-regular);
        font-size: var(--normal-font-size);
        color: var(--primary-text-color);
        z-index: 1;
    }

    input {
        padding-right: 1.65rem;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.detailmodal {
    .fieldscard {
        .formset.expiration_days {
            .error-message {
                float: right;
            }
        }

        .horizontal-alignment {
            .expiration-days-field {
                display: flex;
                flex-direction: row;
                float: right;

                label {
                    font-family: var(--main-font-regular);
                    color: var(--primary-text-color);
                    font-size: var(--normal-font-size);
                    padding: 0;
                    align-self: center;
                }

                input[type='text'] {
                    width: 2.5rem;
                    margin-left: 0.3rem;
                    margin-right: 0.3rem;
                    text-align: center;
                }
            }
        }
    }
}

.add-edit-form {
    .expiration-days-field {
        display: flex;
        flex-direction: row;

        label {
            font-family: var(--main-font-regular);
            color: var(--primary-text-color);
            font-size: var(--normal-font-size);
            align-self: center;
        }

        input[type='text'] {
            width: 2.5rem;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            text-align: center;
        }
    }
}

.job-items-row,
.timerows-row,
.appointments-row,
.attachments-row,
.document-lines-row,
.resource-group-name-row,
.resource-group-resources-row,
.deal-pipeline-stages-row {
    align-items: center;

    .string-value {
        font-family: var(--main-font-regular);
        color: var(--primary-text-color);
        font-size: var(--normal-font-size); 
        padding-right: 0.3rem;
        text-align: right;
    }
}

.job-items-row {
    display: grid;
    grid-template-columns: 2fr 15fr auto auto;
    gap: 0.3rem;

    &.show-price-fields {
        grid-template-columns: 2fr 8fr 2fr 2fr 3fr auto auto;
    }
}

.document-lines {
    .document-lines-settings {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid var(--border-color);
    }

    .document-lines-header {
        display: grid;
        grid-template-columns: 3fr 8fr;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--border-color);

        h6 {
            margin-left: 0;
        }

        .document-lines-settings {
            display: grid;
            grid-template-columns: 1fr 1fr auto auto;
            gap: 0.3rem;
        }
    }

    .edit-mode {
        .vat-reverse-charge {
            display: grid;
            grid-template-columns: auto 2fr 4fr;
            gap: 0.5rem;
            align-items: center;

            .vat-reverse-charge-label {
                font-family: var(--main-font-regular);
                color: var(--primary-text-color);
                font-size: var(--small-font-size);
            }
        }
    }

    .document-lines-row {
        display: grid;
        grid-template-columns: 2fr 8fr 2fr 2fr 3fr auto auto;
        gap: 0.3rem;
        align-items: start;
        font-family: var(--main-font-regular);
        color: var(--primary-text-color);
        font-size: var(--small-font-size);

        .multi-line {
            .custom-dropdown-container {
                padding-bottom: 0.3rem;
            } 
        }

        .price-value {
            text-align: right;
            justify-self: end;
        }
    
        .string-value {
            padding-top: 0.3rem;
            padding-right: 0;
            align-self: start;
        }
    
        .delete-icon {
            padding-top: 0.35rem
        }

        .order-icon {
            padding-top: 0.25rem
        }
    }

    .full-width-alignment {
        .view-mode {
            .p {
                width: 100%;
            }
        }
    }

    .view-mode {
        .document-lines-table {
            padding-top: 0.2rem;

            .table-header {
                display: grid;
                grid-template-columns: 2fr 8fr 2.1fr 2.1fr 2fr;
                width: 100%;
                padding-bottom: 0.25rem;
                border-bottom: 1px solid var(--border-color-dark);

                .header-item {
                    font-size: var(--small-font-size);
                    font-family: var(--main-font-bold);

                    &.text-right {
                        text-align: right;
                        padding-right: 0.5rem;
                    }
                }
            }
        
            .table-row {
                display: grid;
                grid-template-columns: 2fr 8fr 2.1fr 2.1fr 2fr;
                width: 100%;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;

                .text-right {
                    text-align: right;
                    padding-right: 0.5rem;
                }
            }

            .sub-total-summary,
            .vat-summary,
            .total-summary {
                display: grid;
                grid-template-columns: 10fr 2.1fr 2.1fr 2fr;
                width: 100%;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;

                .text-right {
                    text-align: right;
                    padding-right: 0.5rem;
                }
            }
        }

        .vat-reverse-charge {
            padding-top: 2rem;
        }
    }

    .document-lines-totals {
        display: flex;
        flex-direction: column;

        .sub-total-summary,
        .vat-summary,
        .total-summary {
            display: grid;
            grid-template-columns: 8fr 4fr 2fr 3fr auto;
            gap: 0.3rem;
            width: 100%;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            font-family: var(--main-font-regular);
            color: var(--primary-text-color);
            font-size: var(--normal-font-size);

            .text-right {
                text-align: right;
                padding-right: 0.3rem;
            }

            .icons-placeholder {
                width: 34px;
            }
        }
    }
}

.timerows-row {
    display: grid;
    grid-template-columns: 4fr 4fr 3fr 3fr 2fr 11fr auto;
    gap: 0.3rem;

    &.show-end-date-field {
        grid-template-columns: 4fr 4fr 3fr 3fr 4fr 2fr 8fr auto;
    }

    &.show-price-fields {
        grid-template-columns: 4fr 4fr 3fr 3fr 2fr 5fr 3fr 3fr auto;

        &.show-end-date-field {
            grid-template-columns: 4fr 4fr 3fr 3fr 4fr 2fr 5fr 3fr 3fr auto;
        }
    }

    &.duration-only {
        grid-template-columns: 4fr 4fr 2fr 14fr auto;

        &.show-price-fields {
            grid-template-columns: 4fr 4fr 2fr 8fr 3fr 3fr auto;
        }
    }
}

.appointments-row {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 1fr 6fr 3fr auto;
    gap: 0.3rem;
    align-items: start;

    &.show-colors-field {
        grid-template-columns: 3fr 2fr 2fr 1fr 5fr 3fr 2fr auto;
    }

    &.show-end-date-field {
        grid-template-columns: 3fr 2fr 2fr 3fr 1fr 6fr 2fr auto;

        &.show-colors-field {
            grid-template-columns: 3fr 2fr 2fr 3fr 1fr 4fr 2fr 2fr auto;
        }
    }

    .string-value {
        align-self: center;
    }

    .delete-icon {
        padding-top: 0.5rem
    }
}

.attachments-field {
    .dropzone {
        border: 1px dashed var(--border-color);
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--text-color-placeholder);
        padding-right: 0.6rem;
        padding-left: 0.6rem;
    
        &.hover {
            background-color: var(--list-hover-color);
        }

        input[type='file'] {
            display: none;
        }
    }

    .attachments-row {
        display: grid;
        grid-template-columns: auto 6fr 3fr 3fr auto;
        gap: 0.3rem;
        align-items: start;
    
        .attachment-icon-column {
            width: 1rem;
        }
    
        .attachment-name-column {
            grid-column: 1 / 3;
        }
    
        .attachment-icon {
            color: var(--secondary-button-text-color);
            font-family: var(--main-font-regular);
            font-size: var(--normal-font-size);
            padding-left: 0.05rem;
            align-self: center;
        }
    
        .attachment-name {
            align-self: center;
            font-family: var(--main-font-regular);
            font-size: var(--normal-font-size);
            
            .attachment-link {
                text-decoration: underline;
                cursor: pointer;
    
                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    
        .delete-icon {
            padding-top: 0.5rem
        }
    }
}

.deal-pipeline-stages-row {
    display: grid;
    grid-template-columns: 3fr 1fr auto auto;
    gap: 0.3rem;
}

.rows-field {
    .resource-group-rows-field {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 0.3rem;
        // align-items: center;
        margin-bottom: 2rem;

        &.single {
            grid-template-columns: 1fr;
        }

        .group-delete-icon {
            width: 18px;
            margin-top: 2.65rem;
            color: var(--text-color-lightgray);
            font-size: var(--small-font-size);
        }
        
        .group-order-icon {
            width: 16px;
            margin-top: 2.65rem;
            color: var(--text-color-lightgray);
        }

        .resource-group-name {
            margin-bottom: 0.75rem;

            .resource-group-name-row {
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.3rem;
            }
        }
        
        .resource-group-resources-row {
            display: grid;
            grid-template-columns: 2fr 2fr auto auto;
            gap: 0.3rem;
        }
    }
}

.release-item-row {
    display: grid;
    grid-template-columns: 3fr 9fr 2fr auto;
    gap: 0.3rem;

    .row-group {
        .row-group-item {
            margin-bottom: 0.3rem;
        }
    }
}

.release-item-row .delete-icon {
    padding-top: 0.55rem;
}

.stacked-alignment.editable:hover .minus-sign {
    visibility: hidden;
}

.live-edit-field:not(:first-child) .view-in-editmode .stacked-alignment {
    padding-top: 0.5rem;
}

.horizontal-alignment .edit-mode .list-item > * {
    margin: 0;
}

.edit-mode .delete-icon {
    color: var(--text-color-lightgray);
    font-size: var(--small-font-size);
}

.edit-mode .order-icon {
    color: var(--text-color-lightgray);
}

.view-mode .item-link {
    font-size: var(--normal-font-size);
}

.view-mode .item-link,
.rows-field .item-link,
.widget-field .item-link,
.document-lines .item-link {
    font-family: var(--main-font-regular);
    color: var(--primary-text-color);
    text-decoration: underline;
    cursor: pointer;
}

.view-mode .item-link:hover,
.rows-field  .item-link:hover,
.widget-field .item-link:hover,
.document-lines .item-link:hover {
    color: var(--primary-color);
}

.rows-field  .item-list .type,
.widget-field .item-list .type {
    font-family: var(--main-font-light);
}

.rows-field  .add-new-button,
.widget-field .add-new-button,
.document-lines .add-new-button {
    font-size: var(--normal-font-size);
    font-family: var(--main-font-bold);
    color: var(--primary-color);
    margin-top: -0.05rem;
    cursor: pointer;
}

.rows-field .error-message,
.document-lines .error-message {
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--invalid-color);
    padding-top: unset;
}

.live-edit-field .helper-text {
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
    padding-top: 0.25rem;
}

.view-mode .image-smallCircle {
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 65px;
    height: 65px;
}

.view-mode .image-landscape {
    width: 200px;
    min-height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0.2rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.view-mode .image-portrait {
    width: 100px;
    min-height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    border-radius: 0.2rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.switchfield .edit-mode {
    padding-top: 0.3rem;
}

.switchfield .switch-button {
    float: right;
}