/* account.css */

@import './global-variables.scss';

.account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.account-logo {
    height: 2rem;
    margin-bottom: 1.5rem;
}

.account-form {
    width: 284px;
    background-color: var(--background-color-white);
    background-clip: padding-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
    border-radius: 0.3rem;
}

.account-form h4,
.account-form p,
.account-form .form-alert {
    text-align: center;
}

.account-form h4 {
    margin-bottom: 0.5rem;
}

.account-form p {
    margin-top: 0rem;
    margin-bottom: 1rem;
}

.account-form .button-row {
    padding-top: 0.5rem;
}

.account-form input {
    height: 42px;
}

.account-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-buttons a {
    margin-top: 1rem;
}