/* attachment-viewer.css */

@import './global-variables.scss';

.attachment-viewer-modal {    
    .content {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        &.attachment-content {
            height: 1128px;
        }

        &.photo-content {
            height: auto;
        }

        .helper-text {
            font-size: var(--small-font-size);
            font-family: var(--main-font-regular);
            color: var(--text-color-lightgray);
            padding-top: 0.25rem;
        }

        .attachment-viewer {
            flex-grow: 1;

            iframe {
                width: 100%;
                height: 100%;
                border: 0
            }
        }

        .photo-viewer {
            .viewer-image {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
    }
}