/* fonts.css */

@font-face {
    font-family: "Gotham-Bold";
    src: url("../../fonts/gotham-bold.otf") format("opentype"),
         url("../../fonts/gotham-bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham-Medium";
    src: url("../../fonts/gotham-medium.otf") format("opentype"),
         url("../../fonts/gotham-medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham-Book";
    src: url("../../fonts/gotham-book.otf") format("opentype"),
         url("../../fonts/gotham-book.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham-Light";
    src: url("../../fonts/gotham-light.otf") format("opentype"),
         url("../../fonts/gotham-light.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("../../fonts/opensans-semibold.otf") format("opentype"),
         url("../../fonts/opensans-semibold.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("../../fonts/opensans-regular.otf") format("opentype"),
         url("../../fonts/opensans-regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-Light";
    src: url("../../fonts/opensans-light.otf") format("opentype"),
         url("../../fonts/opensans-light.ttf") format("truetype");
}