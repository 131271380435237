/* default-elements.css */

@import './global-variables.scss';

/* * * * * * * * * * * * * * * * 
/* Headers 
* * * * * * * * * * * * * * * */ 

h1, h2, h3, h4, h5, h6 {
    color: var(--primary-text-color);
    margin-top: unset;
    margin-bottom: unset;
}

h1 {
    font-size: var(--h1-font-size);
    font-family: var(--header-font-regular);
    font-weight: 400;
}

h2 {
    font-size: var(--h2-font-size);
    font-family: var(--header-font-regular);
    font-weight: 400;
}

h3 {
    font-size: var(--h3-font-size);
    font-family: var(--header-font-regular);
    font-weight: 400;
}

h4 {
    font-size: var(--h4-font-size);
    font-family: var(--header-font-medium);
    font-weight: 400;
}

h5 {
    font-size: var(--h5-font-size);
    font-family: var(--header-font-regular);
    font-weight: 400;
}

h6 {
    font-size: var(--h6-font-size);
    font-family: var(--header-font-medium);
    font-weight: 400;
}


/* * * * * * * * * * * * * * * * 
/* Paragraphs
* * * * * * * * * * * * * * * */ 

p {
    font-family: var(--main-font-regular);
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}


/* * * * * * * * * * * * * * * * 
/* HTML textarea
* * * * * * * * * * * * * * * */ 

.textarea {
    p {
        margin: unset;

        strong {
            font-family: var(--main-font-bold);
        }
    }
}


/* * * * * * * * * * * * * * * * 
/* Unordered lists
* * * * * * * * * * * * * * * */ 

ul {
    margin: unset;
    padding-left: unset;
}

li {
    list-style-type: none;
}


/* * * * * * * * * * * * * * * * 
/* Main Component 
* * * * * * * * * * * * * * * */ 

body {
    background-color: var(--background-color);
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1 0 auto;
    margin-top: 2.5rem;
}

.bottom-container {
    flex-shrink: 0;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-logo {
    height: 1.25rem;
}

.bottom-slogan {
    padding-top: 0.175rem;
    padding-left: 0.8rem;
    font-size: 0.7rem;
    font-family: var(--header-font-regular);
    color: #B6B6B6;
}


/* * * * * * * * * * * * * * * * 
/* Labels & Badges
* * * * * * * * * * * * * * * */

.badge-container {
    display: flex;
    gap: 0.3rem;
}

.status-badge {
    width: 8rem;
    text-align: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 0.25rem;
    line-height: 1.2rem;
    font-family: var(--header-font-medium);
    color: var(--primary-button-text-color);
    font-size: var(--small-font-size);
}

// Job statuses
.workflow-bar-segment .job_created,
.status-badge.job_created {
    background-color: var(--job_created);
    color: var(--tertiair-text-color);
}

.workflow-bar-segment .one_open_purchase,
.status-badge.one_open_purchase {
    background-color: var(--one_open_purchase);
}

.workflow-bar-segment .all_purchases_delivered,
.status-badge.all_purchases_delivered {
    background-color: var(--all_purchases_delivered);
}

.workflow-bar-segment .one_reserved_appointment,
.status-badge.one_reserved_appointment {
    background-color: var(--one_reserved_appointment);
}

.workflow-bar-segment .one_scheduled_appointment,
.status-badge.one_scheduled_appointment {
    background-color: var(--scheduled_appointment);
}

.workflow-bar-segment .running_travel_timer,
.status-badge.running_travel_timer {
    background-color: var(--running_travel_timer);
}

.workflow-bar-segment .running_work_timer,
.status-badge.running_work_timer {
    background-color: var(--running_work_timer);
}

.workflow-bar-segment .incomplete,
.status-badge.incomplete {
    background-color: var(--incomplete);
}

.workflow-bar-segment .completed,
.status-badge.completed {
    background-color: var(--completed);
}

.workflow-bar-segment .finished,
.status-badge.finished {
    background-color: var(--finished);
}

// Invoice statuses
.status-badge.draft {
    background-color: var(--general-light-gray);
    color: var(--tertiair-text-color);
}

.status-badge.open {
    background-color: var(--general-yellow);
}

.status-badge.overdue {
    background-color: var(--general-red);
}

.status-badge.reminded {
    background-color: var(--general-dark-blue);
}

.status-badge.paid {
    background-color: var(--general-green);
}

// Proposal statuses
.status-badge.draft {
    background-color: var(--general-light-gray);
    color: var(--tertiair-text-color);
}

.status-badge.sent {
    background-color: var(--general-yellow);
}

.status-badge.expired {
    background-color: var(--general-red);
}

.status-badge.won,
.status-badge.accepted {
    background-color: var(--general-green);
}

.status-badge.lost,
.status-badge.rejected {
    background-color: var(--general-red);
}

// Deal statuses
.status-badge.resolution-open {
    background-color: var(--general-green);
}

.status-badge.resolution-won {
    background-color: var(--general-green);
}

.status-badge.resolution-lost {
    background-color: var(--general-red);
}

.label-badge {
    padding: 0.2rem 0.5rem 0.15rem 0.5rem;
    line-height: 1.2rem;
    border-radius: 1rem;
    font-family: var(--main-font-regular);
    color: var(--primary-button-text-color);
    font-size: var(--small-font-size);
}


/* * * * * * * * * * * * * * * * 
/* Background colors 
* * * * * * * * * * * * * * * */

.background-blue {
    background-color: var(--general-blue);
}

.background-dark-blue {
    background-color: var(--general-dark-blue);
}

.background-red {
    background-color: var(--general-red);
}

.background-orange {
    background-color: var(--general-orange);
}

.background-yellow {
    background-color: var(--general-yellow);
}

.background-green {
    background-color: var(--general-green);
}

.background-dark-green {
    background-color: var(--general-dark-green);
}

.background-gray {
    background-color: var(--general-gray);
}

.background-dark-gray {
    background-color: var(--general-dark-gray);
}

// Soft colors

.background-soft-blue {
    background-color: var(--soft-blue);
}

.background-soft-dark-blue {
    background-color: var(--soft-dark-blue);
}

.background-soft-red {
    background-color: var(--soft-red);
}

.background-soft-orange {
    background-color: var(--soft-orange);
}

.background-soft-yellow {
    background-color: var(--soft-yellow);
}

.background-soft-green {
    background-color: var(--soft-green);
}

.background-soft-dark-green {
    background-color: var(--soft-dark-green);
}

.background-soft-gray {
    background-color: var(--soft-gray);
}

.background-soft-dark-gray {
    background-color: var(--soft-dark-gray);
}

.background-soft-purple {
    background-color: var(--soft-purple);
}

.background-soft-lavender {
    background-color: var(--soft-lavender);
}

.background-soft-beige {
    background-color: var(--soft-beige);
}


/* * * * * * * * * * * * * * * * 
/* Containers & Positioning
* * * * * * * * * * * * * * * */ 

.no-container {
    width: 100%;
}

.container, .container-small, .container-medium, .container-filled, .container-wide, .container-wide-filled {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}

.container, .container-small, .container-medium, .container-filled, .container-wide, .container-wide-filled, .row {
    margin-left: auto;
    margin-right: auto;
}

.container, .container-small, .container-medium, .container-wide, .row {
    display: flex;
    flex-direction: row;
}

.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-align {
    display: flex;
    justify-content: flex-end;
}

.text-center {
    text-align: center;
}

.text-right {
    float: right;
}

.align-center {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.gap-3 {
    gap: 0.3rem;
}

.gap-5 {
    gap: 0.3rem;
}

.width-100 {
    width: 100%;
}

@media (min-width: 560px) {
    .container-small {
        max-width: 600px;
    }
}

@media (min-width: 992px) {
    .container-filled, .container-medium, .container, .paginatedlist {
        max-width: 1060px;
    }
}

@media (min-width: 1610px) {
    .container-filled, .container {
        max-width: 1248px;
    }
}

@media (min-height: 1200px) and (min-width: 1200px) {
    .settings-menu {
        margin-top: 2.3rem;
    }
}


/* * * * * * * * * * * * * * * * 
/* Columns 
* * * * * * * * * * * * * * * */ 

.col-15 {
    flex: 0 0 15%;
}

.col-20 {
    flex: 0 0 20%;
}

.col-35 {
    flex: 0 0 35%;
}

.col-40 {
    flex: 0 0 40%;
}

.col-45 {
    flex: 0 0 45%;
}

.col-50 {
    flex: 0 0 50%;
}

.col-55 {
    flex: 0 0 55%;
}

.col-60 {
    flex: 0 0 60%;
}

.col-65 {
    flex: 0 0 65%;
}

.col-80 {
    flex: 0 0 80%;
}

.col-85 {
    flex: 0 0 85%;
}

/* * * * * * * * * * * * * * * * 
/* Display & Visibility
* * * * * * * * * * * * * * * */ 

.hidden {
    display: none;
}

.visibility-hidden {
    visibility: hidden;
}


/* * * * * * * * * * * * * * * * 
/* Modals
* * * * * * * * * * * * * * * */ 

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
}

.modal-backdrop.hidden {
    display: none;
}

.modal {
    background-color: var(--background-color-white);
    background-clip: padding-box;
    z-index: 3;
    position: relative;
    width: auto;
    flex-direction: column;
    align-items: center;
    margin: 1.75rem auto;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
}

.modal.hidden {
    display: none;
}

.modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.modal.extra-small,
.modal.smaller,
.modal.small,
.modal.small-medium,
.modal.medium,
.modal.medium-large {
    padding: 1.5rem;
    border-radius: 0.3rem;
    margin-top: 12rem;
}

.modal.extra-small {
    width: 260px;
}

.modal.smaller {
    width: 280px;
}

.modal.small {
    width: 320px;
}

.modal.small-medium {
    width: 480px;
}

.modal.medium {
    width: 680px;
}

.modal.medium-large {
    width: 760px;
}

.modal.large {
    border-radius: 0.3rem;
    width: 980px;
    margin: 8rem auto;
}

@media (min-width: 1610px) {
    .modal.large {
        width: 1188px;
    }
}

.modal-close {
    color: var(--text-color-lightgray);
}

.modal-close.top-right {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
}

.modal-close:hover {
    color: var(--secondary-color-hover);
    cursor: pointer;
}

/* Modal shake transition */ 
@keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-0.3rem); }
    40% { transform: translateX(0.3rem); }
    60% { transform: translateX(-0.3rem); }
    80% { transform: translateX(0.3rem); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.3s 1;
}


/* * * * * * * * * * * * * * * * 
/* Loader icon
* * * * * * * * * * * * * * * */ 

.loader {
    border: 2px solid #f6f6f6;
    border-top: 3px solid #bababa;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-left: 12px;
    margin-top: 1px;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.modal-loader {
    display: flex;
    justify-content: center;
    padding: 1rem;
    align-items: center;
}


/* * * * * * * * * * * * * * * * 
/* Progress bar
* * * * * * * * * * * * * * * */ 

.progress-bar {
    width: 100%;
    height: 1.5rem;
    background-color: #e0e0e0;
    border-radius: 0.25rem;
    overflow: hidden;
    margin: 10px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: var(--general-green);
    border-radius: 0.25rem;
    transition: width 0.3s ease;
}


/* * * * * * * * * * * * * * * * 
/* List filter dropdown
* * * * * * * * * * * * * * * */ 

.filter-dropdown {
    position: relative;
}

.filter-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    display: block;
    background-color: var(--background-color-white);
    border: 1px solid var(--border-color);
    border-radius: 0.2rem;
    padding: 0.5rem 0;
    min-width: 10rem;
    box-shadow: 0 1px 1px #00000004, 0 2px 4px #00000006, 0 3px 6px #0000000d, 0 4px 8px #00000013, 0 5px 10px #0000001a;
}

.filter-dropdown-item {
    font-family: var(--main-font-bold);
    font-size: var(--normal-font-size);
    color: var(--secondary-button-text-color);
    cursor: pointer;
    display: block;
    line-height: 1.2rem;
    padding: 0.3rem 1.2rem;
}

.filter-dropdown-item:hover {
    background-color: var(--list-hover-color);
}

.filter-dropdown-icon {
    margin-left: 0.4rem;
    padding-top: 0.1rem;
} 

/* * * * * * * * * * * * * * * * 
/* TabBar & PageHeader
* * * * * * * * * * * * * * * */ 

.section,
.pageheader {
    background-color: var(--background-color-white);
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
}

.explanation-text {
    margin-top: 0.8rem;
    margin-bottom: 0;
}

.pageheader .tabbar {
    margin-top: 0.5rem;
    margin-bottom: -1rem;
}

.pageheader .tabbar li {
    display: inline-block;
    padding-bottom: 0.7rem;
    padding-top: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: var(--header-font-medium);
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
    border-bottom: 2px solid transparent;
}

.pageheader.disabled,
.pageheader.disabled h3,
.pageheader.disabled .explanation-text {
    color: var(--text-color-lightgray);
}

.tabbar li:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.tabbar li.active {
    color: var(--primary-color);
    cursor: default;
}

.pageheader .tabbar li:hover, 
.pageheader .tabbar li.active {
    border-bottom: 2px solid var(--primary-color);
}

.pageheader .tabbar li:first-child {
    margin-left: -1.0rem;
}

.upgrade-badge {
    margin-left: 0.75rem;
    padding: 0.25rem 0.4rem 0.25rem 0.4rem;
    border-radius: 0.7rem;
    font-size: var(--smaller-font-size);
    font-family: var(--header-font-medium);
    color: var(--primary-button-text-color);
    background-color: var(--general-yellow);
    cursor: pointer;
}