/* settings.css */

@import './global-variables.scss';

// Custom css for tabs fields in settings forms or modals
.settings-form {
    .setting-item {
        display: flex;
        align-items: flex-start;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    .switch-button-wrapper {
        flex-shrink: 0;
        margin-right: 0.5rem;
    }

    .title {
        flex-grow: 1;
        display: flex;
        // flex-direction: column;
        align-items: flex-start;

        &.switch h5 {
            margin-top: 0.1rem;
        }
    }

    .content {
        width: 100%;
        margin-top: 0.5rem;

        .explanation-text {
            margin-top: 0;
            font-family: var(--header-font-regular);
        }

        .dropdown-container {
            width: 200px;
            max-width: 100%;
        }

        .timefield-container {
            width: 100px;
            max-width: 100%;
        }

        .multiselect-container {
            width: 400px;
        }
    }
}

.settings-form > div.setting-item > div > div:not(.title) > div:not(:last-child) {
    margin-bottom: 0.5rem;
}

.settings-list {
    background-color: var(--background-color-white);
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);

    .title {
        display: flex;
        flex-direction: row;
    }

    .container, .container-filled {
        padding: unset;
    }

    .list {
        box-shadow: unset;
    }

    .explanation {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        .text {
            width: 70%;
        }

        .video {
            width: 30%;
        }
    }



}