/* list.css */

@import './global-variables.scss';

.list-button-wrapper {
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.list {
    background-color: var(--background-color-white);
    border-spacing: unset;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    table-layout: fixed;
    width: 100%;
}

.list th,
.list td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.list td.delete-column,
.list td.edit-column {
    padding-left: 0;
    overflow: unset;
}

.list th {
    border-spacing: unset;
    text-align: left;
    font-family: var(--main-font-bold);
    font-size: var(--normal-font-size);
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    line-height: 1.2rem;
    position: relative;
}

.list th:hover {
    cursor: pointer;
}

.list th .sorting-icon {
    margin-left: 0.3rem;
    font-size: 1rem;
}

.list tr {
    display: grid;
    align-items: center;
}

.list thead tr {
    border-bottom: 1px solid var(--border-color);
}

.list tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.paginated-list .list tbody tr {
    border-bottom: 1px solid var(--border-color);
}

.paginated-list .list tbody tr:nth-child(50) {
    border-bottom: none;
}

.list td {
    border-spacing: unset;
    overflow: hidden; 
    white-space: nowrap;
}

.list td.data,
.list td .data {
    text-align: left;
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
    font-family: var(--main-font-light);
    line-height: 2.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list tr.group-header {
    text-align: left;
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
    font-family: var(--main-font-regular);
    line-height: 2.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.list tbody tr:hover {
    background-color: var(--list-hover-color);
    cursor: pointer;
}

.list tbody tr.group-header:hover {
    background-color: var(--background-color-white);
    cursor: default;
}

.paginated-list {
    min-height: calc(100vh - 216px);
    background-color: var(--background-color-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    position: relative;
}

.paginated-list .list {
    box-shadow: unset;
}

.non-paginated-list .list tbody tr:last-child {
    border-bottom: none;
}

/* overflow needs to be visible, otherwise the tooltips in the list are not showing */
.list td.tooltip-column {
    overflow: visible;
}

.list td .tooltip-icon {
    align-self: center;
}

.list td .list-drag-icon {
    position: relative;
    color: var(--text-color-lightgray);
    padding-right: 0.5rem;
    cursor: grab;
}

.list td .list-drag-icon:active {
    cursor: grabbing;
}

.list td .list-delete-icon,
.list td .list-edit-icon {
    color: var(--text-color-lightgray);
    font-size: 0.85rem;
}

.list td .list-edit-icon {
    padding-top: 2px;
}

.empty-list {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color-white);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: -1px;
}

.paginated-list .empty-list {
    position: absolute;
    box-shadow: unset;
    border-top: unset;
    background-color: var(--background-color-white);
    padding-top: unset;
    padding-bottom: unset;
    margin-top: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    text-align: center;
}

.empty-list p {
    font-size: var(--small-font-size);
    color: var(--text-color-lightgray);
    text-align: center;
}

.detail-list {
    .paginated-list {
        min-height: 10rem;

        .empty-list {
            margin-top: 1rem;
        }
    }
}

.loading-rectangle {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    height: 1.2rem;
    background-color: var(--list-loading-rectangle-color);
    border-radius: 3px;
    width: 70%;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.pagination-buttons button {
    margin: 0 0.5rem;
}

.pagination-pages {
    font-family: var(--main-font-light);
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
}