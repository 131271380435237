/* image-editor.css */

@import './global-variables.scss';

.image-editor-modal {    
    .content {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .edit-controls {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            

            .reactEasyCrop_Container {
                position: relative;
                width: 280px;
                height: 280px;
            }
        }

        .helper-text {
            font-size: var(--small-font-size);
            font-family: var(--main-font-regular);
            color: var(--text-color-lightgray);
            padding-top: 0.25rem;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
    }
}