/* card.scss */

.card {
    background-color: var(--background-color-white);
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    position: relative;
    margin-bottom: 1.5rem;

    .loader {
        margin-left: calc(50% - 9px);
        margin-right: auto;
        margin-top: 6px;
        border: 2px solid #ffffff;
        border-top: 3px solid #bababa;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: top;

        h6:not(:last-child) {
            margin-bottom: 0.1rem;
        }
    }

    .card-list-item {
        display: flex;
        font-family: var(--main-font-regular);
        color: var(--primary-text-color);
        font-size: var(--normal-font-size);

        .card-link {
            color: var(--primary-text-color);
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
            }
        }

        .unlink-icon {
            display: none;
            color: var(--text-color-lightgray);
            font-size: 0.85rem;
            margin-left: 0.35rem;
            padding-top: 0.025rem;

            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
        }

        &:hover {
            .unlink-icon {
                display: block;
            }
        }
    }

    .card-list-item:not(:last-child) {
        padding-bottom: 0.15rem;
    }

    &.linked-items-card {
        padding-top: 0.7rem;

        .header {
            align-items: center;
            margin-bottom: 0.25rem;
            
            h6 {
                padding-top: 0.15rem;
                margin-bottom: unset;
            }
        }   
    }

    &.linked-documents-card {

        .linked-documents-list {


            // .header {
            //     font-family: var(--header-font-medium);
            //     color: var(--primary-text-color);
            //     font-size: var(--small-font-size);
            //     margin-top: 0.5rem;
            //     margin-bottom: 0.25rem;
            // }


            .linked-document {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                font-family: var(--main-font-regular);
                color: var(--primary-text-color);
                font-size: var(--normal-font-size);
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                gap: 0.3rem;

                &:not(:last-child) {
                    // border-bottom: 1px solid var(--border-color);
                }

                &:hover {
                    background-color: var(--list-hover-color);
                    cursor: pointer;
                }

                .document-name {
                    flex-grow: 1;
                }
    
                .status-badge {
                    width: unset;
                    padding: 0.1rem 0.3rem;
                }
            }
        }
    }

    &.contact-card {
        padding: 0.7rem 0.7rem 1rem 0.7rem;

        .header {
            align-items: center;
            margin-bottom: 0.25rem;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            
            h6 {
                padding-top: 0.15rem;
                margin-bottom: unset;
            }

            .buttons {
                display: flex;
                gap: 0.3rem;
            }
        }

        .linked-contact {
            display: flex;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            margin-top: 0.75rem;
            margin-bottom: 0.25rem;
            align-items: center;

            .linked-contact-icon {
                font-size: 1.5rem;
                margin-right: 0.5rem;
            }

            .linked-contact-link {
                
                font-family: var(--main-font-bold);
                color: var(--primary-text-color);
                font-size: var(--normal-font-size);
                text-decoration: underline;
                cursor: pointer;
    
                &:hover {
                    color: var(--primary-color);
                }
            }
        }

        .link-button {
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            font-size: var(--normal-font-size);
            font-family: var(--main-font-bold);
            color: var(--primary-color);
            margin-top: -0.05rem;
            cursor: pointer;
        }
    }
}

.fieldscard {
    padding: 1rem 0.7rem 1rem 0.7rem;
}

.fieldscard h6,
.appointmentscard h6 {
    margin: 0 0.3rem;
}

.appointmentscard p {
    margin-bottom: 0.4rem;
}

.photoscard {
    .photos-header {
        display: flex;
        flex-direction: row;

        .tooltip-icon {
            font-size: 0.95rem;
            padding-left: 0.3rem;
            z-index: 2;
        }
    }

    .photos-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .photo-box {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .photo {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 0.35rem;
            }

            .tooltip {
                z-index: 3;
                text-align: center;
            }
        }
    }
}