/* global-variables.css */

@import './fonts.scss';

:root {
    /* Colors */
    --primary-color: #fa5d00;
    --primary-color-hover: #ff7a44;
    --primary-color-background-selected: #ffd1a3;
    --primary-shadow-color: #df4203;
    --secondary-color: #D8D8D8BF;
    --secondary-color-hover: #d6d6d6;
    --secondary-shadow-color: #b6b6b6;
    --background-color: #f6f6f6;
    --background-color-white: #ffffff;
    --background-color-note: #FEFDDD;
    --background-color-dark: #2a2e3d;
    --background-color-darkest: #1a1d27;
    --primary-button-text-color: #ffffff;
    --secondary-button-text-color: #505050;
    --tertiair-button-text-color: #2a2e3d;
    --border-color: #d8d8d8; 
    --border-color-light: #eaeaea; 
    --border-color-darkgray: #9b9b9b;
    --border-color-dark: #1a1d27; 
    --navbar-color: #2a2e3d;
    --primary-text-color: #1a1d27;
    --secondary-text-color: #2a2e3d;
    --tertiair-text-color: #505050;
    --text-color-white: #ffffff;
    --text-color-lightgray: #9b9b9b;
    --text-color-placeholder: #757575;
    --list-loading-rectangle-color: #efefef;
    --list-hover-color: #f4f4f4;
    --status-bar-color: #ececec;
    --menu-hover-color: #cccccc;
    --warning-color: #e84646;
    --invalid-color: #e84646;
    --tooltip-color: #333333;
    --tab-button-color: #F0EFEF;
    --disabled-color: #F0EFEF;
    --hyperlink-color: #0073e6;

    /* Status colors */
    --positive-green: #2BBF54;
    --dark-green: #2f921c;
    --overdue: #e84646;

    /* General glossy colors */
    --general-blue: #147af0;
    --general-dark-blue: #2a2e3d;
    --general-purple: #721ea9;
    --general-red: #e84646;
    --general-red-hover: #f06565;
    --general-orange: #fa5d00;
    --general-yellow: #FD9E00;
    --general-green: #2BBF54;
    --general-green-hover: #44d06b;
    --general-dark-green: #2f921c;
    --general-light-gray: #d8d8d8;
    --general-gray: #9b9b9b;
    --general-dark-gray: #505050;

    /* Custom soft colors */
    --soft-blue: #6B9EE6;
    --soft-dark-blue: #8BA6C1;
    --soft-red: #E38A8A;
    --soft-orange: #F8A47B;
    --soft-yellow: #FFD580;
    --soft-green: #73C98B;
    --soft-dark-green: #A5D1A5;
    --soft-gray: #D3D3D3;
    --soft-dark-gray: #B0B8C1;
    --soft-purple: #9D6FC3;
    --soft-lavender: #B89AD3;
    --soft-beige: #E8D5B7;

    /* Status condition colors */
    --job_created: #d8d8d8;
    --one_open_purchase: #721ea9;
    --all_purchases_delivered: #2BBF54;
    --one_reserved_appointment: #9b9b9b;
    --scheduled_appointment: #2a2e3d;
    --running_travel_timer: #147af0;
    --running_work_timer: #FD9E00;
    --incomplete: #fa5d00;
    --completed: #2BBF54;
    --finished: #2f921c;

    /* Fonts */
    --main-font-light: 'OpenSanS-Light', sans-serif;
    --main-font-regular: 'OpenSans-Regular', sans-serif;
    --main-font-bold: 'OpenSans-SemiBold', sans-serif;
    --header-font-light: 'Gotham-Light', sans-serif;
    --header-font-regular: 'Gotham-Book', sans-serif;
    --header-font-medium: 'Gotham-Medium', sans-serif;
    --button-font: 'Gotham-Medium', sans-serif;

    /* Headers */
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --h4-font-size: 1rem;
    --h5-font-size: 1rem;
    --h6-font-size: 0.9rem;

    /* Text */
    --smaller-font-size: 0.75rem;
    --small-font-size: 0.8rem;
    --normal-font-size: 0.875rem;
    --larger-font-size: 0.9rem;
    --large-font-size: 0.95rem;
}