/* import.scss */

@import './global-variables.scss';

.import-wizard {
    .dropzone {
        border: 1.5px dashed var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--text-color-placeholder);
        padding-right: 0.6rem;
        padding-left: 0.6rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        height: unset;
        margin-bottom: 1.5rem;
    
        &.hover {
            background-color: var(--list-hover-color);
        }

        input[type='file'] {
            display: none;
        }
    }

    .import-type-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1.25rem;

        .import-type-column {
            padding: 2.5rem 1.5rem 2rem 1.5rem;
            border: 1px solid var(--border-color);
            background-color: #f9f9f9;
            border-radius: 0.2rem;
            text-align: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                border-color: var(--primary-color);
                background-color: var(--primary-color-background-selected);
            }

            .import-type-icon {
                font-size: 3rem;
                color: var(--primary-text-color);
                padding-bottom: 1rem;
            }

            .label {
                max-width: 290px;
                text-align: center;
                font-family: var(--header-font-regular);
                font-size: var(--large-font-size);
                margin: 0 auto;
            }
        }
    }

    .import-mapping-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1.25rem;

        .import-mapping-column {
            padding: 0.75rem;
            border: 1px solid var(--border-color);
            background-color: #f9f9f9;
            border-radius: 0.2rem;
            text-align: center;

            &.mapped {
                border-color: var(--primary-color);
                background-color: var(--primary-color-background-selected);

                .mapping-preview {
                    background-color: var(--primary-color-background-selected);
                }
            }

            h4 {
                padding-bottom: 0.75rem;
            }

            .mapping-preview {
                border: 1px solid var(--border-color);
                background-color: var(--background-color-white);
                margin-bottom: 0.75rem;

                li {
                    padding-left: 0.5rem;
                    text-align: left;
                }

                li:not(:last-child) {
                    border-bottom: 1px solid var(--border-color);
                }
            }
        }
    }

    .import-validation-grid, .import-overview {
        .import-validation-column, .import-overview-column {
            border: 1px solid var(--border-color);
            background-color: #f9f9f9;
            border-radius: 0.2rem;
            padding: 0.75rem;
            margin-bottom: 1rem;

            p {
                margin-bottom: 0;
            }

            .checkbox-item {
                justify-content: unset;
                margin-top: 0.75rem;
            }

            .import-example-table {
                border: 1px solid var(--border-color);
                background-color: var(--background-color-white);
                margin: 0.75rem 0 0.75rem 0;
                border-collapse: collapse;

                td.row-number {
                    min-width: 1.5rem;
                    text-align: center;
                    font-family: var(--main-font-bold);
                    font-size: var(--normal-font-size);
                    padding-left: 0;
                }

                th {
                    font-family: var(--main-font-bold);
                    font-size: var(--normal-font-size);
                }

                th,
                td {
                    border-bottom: 1px solid var(--border-color);
                    padding-left: 0.25rem;
                    min-width: 10rem;

                    p {
                        margin: 0.25rem 0 0.25rem 0; 
                    }
                }

                th:not(:last-child),
                td:not(:last-child) {
                    border-right: 1px solid var(--border-color);
                }

                tr:last-child td {
                    border-bottom: none;
                }
            }

            .import-validation-mapping-options {
                margin-top: 1rem;

                .import-validation-mapping-label {
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;
                    margin-bottom: 0.25rem;
                    
                    div {
                        width: 18rem;
                        font-family: var(--main-font-regular);
                        font-size: var(--normal-font-size);
                    }
                }

                .import-validation-mapping {
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;
                    margin-bottom: 0.75rem;
    
                    .import-validation-mapping-value {
                        width: 18rem;
                        font-family: var(--main-font-regular);
                        font-size: var(--normal-font-size);
                    }
    
                    select {
                        width: 18rem;
                    }
                }
            }
        }
    }

    .import-overview {
        .import-overview-column {
            h6 {
                margin-top: 1.5rem;
            }

            .mapping-item {
                display: flex;

                .icon {
                    padding: 0.7rem 0.25rem 0 0.25rem;
                    font-size: 0.75rem;
                    color: var(--tertiair-text-color);
                }
            }

            .import-tag {
                display: flex;

                .icon {
                    padding-top: 0.5rem;
                    padding-right: 0.25rem;
                    font-size: 1.2rem;
                    color: var(--text-color-lightgray);
                }
            }
        }
    }

    button.button-primary {
        svg {
            padding-top: 0.15rem;
            padding-left: 0.35rem;
            font-size: 0.75rem;
        }
    }
}