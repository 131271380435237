/* dashboard.css */

@import './global-variables.scss';

.dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;

    div {
        h5 {
            padding-bottom: 0.5rem;
        }
    }

    .greeting {
        margin-bottom: 3rem;
    }

    .widgets-section {
        .widgets-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.5rem;
            margin-bottom: 1.5rem;

            .widget {
                min-height: 5.6rem;
            }
        }
    }

    .workflow-section {
        margin-bottom: 3.5rem;

        .workflow-bar {
            display: flex;
            width: 100%;
            height: 2rem;

            .workflow-bar-segment {
                display: flex;
                position: relative;
            }

            .workflow-bar-segment:first-child {
                div:first-child {
                    border-top-left-radius: 0.3rem;
                    border-bottom-left-radius: 0.3rem;
                }
            }

            .workflow-bar-segment:last-child {
                div:last-child {
                    border-top-right-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                }
            }
        }

        .feature-labels {
            display: flex;
            width: 100%;
            padding-top: 0.25rem;

            .label {
                border-left: 1px solid var(--border-color);
                padding-left: 0.5rem;
                font-size: var(--small-font-size);
                font-family: var(--header-font-regular);
            }
        }
    }

    .todos-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        &.releases {
            grid-template-columns: 6fr 4fr;
        }

        .todos {
            .section {
                padding: unset;

                .responsibility-group:not(:last-child) {
                    padding-bottom: 2rem;
                    border-bottom: 1px solid var(--border-color);
                }

                .responsibility-group {
                    h6 {
                        padding-top: 1rem;
                        padding-bottom: 0.5rem;
                        margin-left: 1rem;
                    }

                    .todo-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;

                        .label {
                            margin-left: 1rem;
                            font-size: var(--larger-font-size);
                            font-family: var(--header-font-regular);
                        }

                        .icon {
                            margin-top: 0.125rem;
                            margin-right: 1rem;
                            color: var(--text-color-lightgray)
                        }
                    }

                    .todo-row:not(:first-child) {
                        border-top: 1px solid var(--border-color);
                    }

                    .todo-row:hover {
                        background-color: var(--list-hover-color);
                        cursor: pointer;
                    }

                    .finished-todo-row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;

                        .label {
                            margin-left: 1rem;
                            font-size: var(--larger-font-size);
                            font-family: var(--header-font-regular);
                        }
                    }
                }
            }
        }

        .releases {
            .section {
                padding: unset;
                min-height: 2rem;
                position: relative;

                .loader-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    .loader {
                        margin-left: 0;
                    }
                }

                .release {
                    padding: 0.6rem 0 0.6rem 0;

                    .header {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 0.75rem 0 0.75rem;

                        .title {
                            padding-top: 0.35rem;
                            font-size: var(--larger-font-size);
                            font-family: var(--header-font-regular)
                        }                    
                    }

                    .description {
                        padding: 0 0.75rem 0 0.75rem;
                        margin-bottom: unset;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }  

                    &:hover {
                        background-color: var(--list-hover-color);
                        cursor: pointer;
                    }

                    &:not(:first-child) {
                        border-top: 1px solid var(--border-color);
                    }
                }

                .release-list-link {
                    padding: 0.25rem 0.75rem 1rem 0.75rem;
                    display: flex;
                    justify-content: flex-end;

                    .release-link {
                        cursor: pointer;
                        font-family: var(--header-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--normal-font-size);

                        &:hover {
                            color: var(--primary-color);
                        }

                        .link-arrow {
                            padding-left: 0.25rem;
                            padding-top: 0.05rem; 
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}