/* widgets.css */

@import './global-variables.scss';

.widget {
    background-color: var(--background-color-white);
    position: relative;

    .title {
        display: flex;
        align-items: center;
        font-size: var(--h6-font-size);
        font-family: var(--header-font-medium);

        .tooltip-icon {
            padding-top: 0.1rem;
            padding-left: 0.3rem;
            font-size: var(--small-font-size);
        }
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;

        .loader {
            margin-left: 0;
        }
    }

    .number-widget {
        display: flex;
        flex-direction: column;
        padding-top: 0.75rem;
    
        .current-number {
            display: flex;
            align-items: baseline;
            padding-bottom: 0.75rem;
            gap: 0.3rem;
    
            h1 {
                font-size: 2rem;
                font-family: var(--header-font-medium);
            }
    
            .prefix {
                font-size: var(--larger-font-size);
                font-family: var(--main-font-regular);
            }
    
            .measuring-unit, .label {
                font-size: var(--larger-font-size);
                font-family: var(--header-font-regular);
            }
        }
    
        .difference {
            display: flex;
            flex-direction: row;
            font-size: var(--larger-font-size);
            font-family: var(--header-font-regular);
    
            .positive {
                color: var(--general-green);
            }
    
            .negative {
                color: var(--general-red);
            }
        }
    }
}