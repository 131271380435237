/* datepicker.css */

@import './global-variables.scss';

.dateinput {
    position: relative;
    // display: inline-block;
}

.dateinput-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.dateinput .datepicker {
    position: absolute;
    margin: 0.25rem 0;
    top: 100%;
    left: 0;
    background-color: var(--background-color-white);
    border-radius: 0.5rem;
    box-shadow: 0 0 20px rgba(10, 10, 10, 0.25);
    z-index: 2;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
}

.month-selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
}

.month-selector span {
    font-family: var(--header-font-medium);
    font-size: var(--normal-font-size);
}

.month-selector .month-angle {
    padding: 0 0.25rem;
    cursor: pointer;
}

.header-row, 
.week-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.week-row {
    margin-bottom: 0.25rem;
}

.datepicker {
    .day-name, 
    .day, 
    .other-month {
        font-size: var(--normal-font-size);
        flex-grow: 1;
        text-align: center;
        width: 1.8rem;
        padding: 0.3rem 0;
    }

    .day-name.week-number-header {
        font-size: var(--normal-font-size);
        font-family: var(--header-font-regular);
        color: var(--text-color-placeholder);
        flex-grow: 1;
        text-align: center;
        width: 1.8rem;
        padding: 0.3rem 0;
        padding-right: 0.35rem;
    }

    .week-number {   
        flex-grow: 1;
        padding: 0.3rem 0;
        font-size: var(--normal-font-size);
        font-family: var(--main-font-regular);
        color: var(--text-color-placeholder);
        text-align: center;
        width: 1.8rem;
        padding-right: 0.35rem;
    }

    .day-name {
        font-family: var(--header-font-medium);
    }

    .day, 
    .day.other-month {
        font-family: var(--main-font-regular);
        cursor: pointer;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .day.today {
        background-color: var(--secondary-color);
    }

    .day:hover, 
    .day.other-month:hover {
        color: var(--primary-button-text-color);
        background-color: var(--primary-color);
    }

    .day.selected {
        color: var(--primary-button-text-color);
        background-color: var(--primary-color);
    }

    .other-month {
        color: var(--text-color-lightgray)
    }
}

