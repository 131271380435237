/* status-bar.css */

@import './global-variables.scss';

.status-bar {
    list-style: none;
    overflow: hidden;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-left: 0;
    margin: 0;
    border-radius: 0.25rem;
    box-sizing: border-box;

    .status-step {
        background-color: var(--status-bar-color);
        position: relative;
        box-sizing: content-box;
        padding: 0;
        width: auto;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        
        .status-name {
            font-family: var(--header-font-medium);
            font-size: var(--larger-font-size);
            font-weight: 400;
        }

        &:not(:first-child) {
            .status-name {
                margin-left: 1rem;
            }
        }

        &:last-child .status-name {
            margin-left: 0.5rem;
        }

        &:not(:last-child)::before,
        > *::before {
            margin-left: -0.15rem;
        }
        
        &:not(:last-child)::before,
        &:not(:last-child)::after,
        > *::before,
        > *::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 2rem solid transparent;
            border-bottom: 2rem solid transparent;
            border-left: 1rem solid var(--background-color-white);
            position: absolute;
            top: 50%;
            margin-top: -2rem;
            left: 100%;
            z-index: 1;
        }

        &:not(:last-child)::after,
        > *::after {
            border-left-color: var(--status-bar-color);
            margin-left: -0.35rem;
        }

        // Condition colors
        &.job_created {
            background-color: var(--job_created);
            color: var(--tertiair-text-color);

            &::after {
                border-left-color: var(--job_created);
            }            
        }

        &.one_open_purchase {
            background-color: var(--one_open_purchase);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--one_open_purchase);
            }            
        }

        &.all_purchases_delivered {
            background-color: var(--all_purchases_delivered);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--all_purchases_delivered);
            }            
        }

        &.one_reserved_appointment {
            background-color: var(--one_reserved_appointment);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--one_reserved_appointment);
            }            
        }

        &.one_scheduled_appointment {
            background-color: var(--scheduled_appointment);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--scheduled_appointment);
            }            
        }

        &.running_travel_timer {
            background-color: var(--running_travel_timer);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--running_travel_timer);
            }            
        }

        &.running_work_timer {
            background-color: var(--running_work_timer);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--running_work_timer);
            }            
        }

        &.incomplete, 
        &.expired {
            background-color: var(--incomplete);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--incomplete);
            }            
        }

        &.completed,
        &.accepted {
            background-color: var(--completed);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--completed);
            }            
        }

        &.finished {
            background-color: var(--finished);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--finished);
            }

            > *::after {
                border-left-color: var(--finished);
            }
        }

        &.rejected {
            background-color: var(--general-red);
            color: var(--text-color-white);

            &::after {
                border-left-color: var(--general-red);
            }            
        }
    }

    &.status-clickable {
        .status-step {
            .status-name {
                &:not(.current-status) {
                    visibility: hidden;
                }
            }

            &:hover {
                .status-name {
                    &:not(.current-status) {
                        visibility: visible;
                    }
                }
            }
        }

        &.lost,
        &.won {
            .status-step:hover {
                .status-name {
                    &:not(.current-status) {
                        visibility: hidden;
                    }
                }
            }
        }
    
        &:not(.lost):not(.won) {
            .status-step {
                &.active:not(.current) {
                    &:hover {
                        cursor: pointer;
                    }
                }

                &:not(.active) {
                    color: var(--status-bar-color);

                    &:hover {
                        color: var(--text-color-placeholder);
                        cursor: pointer;
                    }
                }
            }
        }        
    }
}