/* forms.css */

@import './global-variables.scss';

label, input, button {
    display: block;
}

label {
    font-family: var(--main-font-regular);
    font-size: var(--normal-font-size);
    color: var(--primary-text-color);  
    padding-bottom: 0.1rem; 
}

.disabled label {
    color: var(--text-color-lightgray);
}

.input-text-label, 
.input-dropdown-label {
    padding-bottom: 0.25rem;
}

input[type=text],
input[type=number], 
input[type=email],
input[type=password], 
textarea, 
select,
.custom-dropdown-field,
.dropzone {
    background-color: var(--background-color-white);
    border: 1px solid var(--border-color);
    box-shadow: inset 0 2px #0000000a;
    border-radius: 0.3rem;
    font-family: var(--header-font-regular);
    font-size: var(--normal-font-size);
    color: var(--primary-text-color);
    vertical-align: middle;
    line-height: normal;
    box-sizing: border-box;
    height: 36px;
    width: 100%;
}

.disabled input[type=text] {
    color: var(--text-color-lightgray);
    cursor: not-allowed;
}

.tabs-field {
    width: 100%;
    display: flex;
}

.tabs-field {
    button {
        border: 1px solid var(--border-color);
        background-color: var(--background-color-white);
        font-family: var(--header-font-regular);
        font-size: var(--normal-font-size);
        color: var(--text-color-placeholder);
        height: 36px;
        cursor: pointer;

        &:hover:enabled, &.active:enabled {
            background-color: var(--tab-button-color);
            color: var(--primary-text-color);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: var(--background-color);
        }
    }

    &.large {
        border: 1px solid var(--border-color);
        border-radius: 0.3rem;
        display: inline-flex;
        width: unset;
        background-color: var(--background-color-white);

        button {
            color: var(--text-color-placeholder);
            margin-left: 1px;
            height: 44px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border: none;

            &:hover:enabled {
                color: var(--primary-text-color);
                background-color: var(--background-color-white);
            }

            &.active:enabled {
                color: var(--primary-text-color);
                background-color: var(--tab-button-color);
                font-family: var(--header-font-medium);
                border: 1px solid var(--tab-button-color);
                margin-left: 0px;
                border-radius: 0.3rem;
                box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.05), -1px 0px 1px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.horizontal-alignment .tabs-field button {
    flex-grow: 1;
}

.stacked-alignment .tabs-field button {
    flex-grow: 0;
}

.add-edit-form {
    .tabs-field {
        button.stretched {
            flex: 1;
        }
    }
}

.tabs-field button .tab-option-icon {
    color: var(--secondary-button-text-color);
    padding-right: 0.3rem;
}

.tabs-field button + button {
    margin-left: -1px;
}

.tabs-field button:first-child {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.tabs-field button:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.tabs-field.icon-only {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: -0.75rem;
    width: 100%;
}

.tabs-field.icon-only button {
    flex-grow: 1;
    margin-bottom: 0.75rem;
    margin-left: -1px;
}

.tabs-field.icon-only button .tab-option-icon {
    color: var(--secondary-button-text-color);
    padding-right: unset;
}

/* Styling of the tabs-field label icon */
.tabs-field button.label {
    position: relative;
}

.tabs-field button.label::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: block;
}

.tabs-field button.label.background-dark-blue::before {
    background-color: var(--general-dark-blue);
}

.tabs-field button.label.background-blue::before {
    background-color: var(--general-blue);
}

.tabs-field button.label.background-red::before {
    background-color: var(--general-red);
}

.tabs-field button.label.background-orange::before {
    background-color: var(--general-orange);
}

.tabs-field button.label.background-yellow::before {
    background-color: var(--general-yellow);
}

.tabs-field button.label.background-green::before {
    background-color: var(--general-green);
}

.tabs-field button.label.background-dark-green::before {
    background-color: var(--general-dark-green);
}

.tabs-field button.label.background-gray::before {
    background-color: var(--general-gray);
}

.tabs-field button.label.background-dark-gray::before {
    background-color: var(--general-dark-gray);
}

.tabs-field {
    button {
        &.label {
            &.soft-dark-blue::before {
                background-color: var(--soft-dark-blue);
            }
            &.soft-blue::before {
                background-color: var(--soft-blue);
            }
            &.soft-dark-green::before {
                background-color: var(--soft-red);
            }
            &.soft-green::before {
                background-color: var(--soft-orange);
            }
            &.soft-yellow::before {
                background-color: var(--soft-yellow);
            }
            &.soft-orange::before {
                background-color: var(--soft-green);
            }
            &.soft-red::before {
                background-color: var(--soft-dark-green);
            }
            &.soft-gray::before {
                background-color: var(--soft-gray);
            }
            &.soft-dark-gray::before {
                background-color: var(--soft-dark-gray);
            }
            &.soft-purple::before {
                background-color: var(--soft-purple);
            }
            &.soft-lavender::before {
                background-color: var(--soft-lavender);
            }
            &.soft-beige::before {
                background-color: var(--soft-beige);
            }
        }
    }
}

input[type=text], 
input[type=number], 
input[type=password], 
input[type=email],
textarea {
    -webkit-appearance: none;
    appearance: none;
    cursor: text;
    padding-right: 0.6rem;
    padding-left: 0.6rem;
}

textarea {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    line-height: 1.2rem;
    overflow-wrap: break-word;
}

select,
.custom-dropdown-field {
    cursor: pointer;
    padding-right: 0.26rem;
    padding-left: 0.6rem;
}

.custom-dropdown-field {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.2rem;
}

.custom-dropdown-field.disabled {
    cursor: not-allowed;
    background-color: var(--disabled-color);
    box-shadow: unset;
    color: var(--text-color-placeholder);
}

.live-edit-field .custom-dropdown-field {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    line-height: 1.2rem;
}

.live-edit-field input,
.live-edit-field textarea,
.live-edit-field select,
.live-edit-field .custom-dropdown-field,
.live-edit-field .tabs-field button {
    height: 34px;
}

.detailmodal {
    .live-edit-field input,
    .live-edit-field textarea,
    .live-edit-field select,
    .live-edit-field .custom-dropdown-field,
    .live-edit-field .tabs-field button {
        height: 30px;
    }
}

input:focus, 
select:focus, 
textarea:focus {
    outline: none;
    border-color: var(--primary-color)
}

.add-edit-form .formfield {
    margin-bottom: 0.7rem;
}

.field-row,
.street-row,
.city-row,
.field-row-next {
    display: flex;
    gap: 0.5rem;
}

.add-edit-form .tooltip-icon {
    padding-left: 0.3rem;
}

.formset .button-row {
    display: flex;
    justify-content: space-between;
}

.live-edit-field .formset .button-row {
    display: flex;
    justify-content: flex-end;
}

.formset .buttons-left {
    display: flex;
    margin-top: 0.3rem;
}

.formset .buttons-right {
    display: flex;
    float: right;
    margin-top: 0.3rem;
}

.formset .buttons-right button {
    margin-left: 0.5rem;
}

.live-edit-field .formset .buttons-right button {
    margin-left: 0.3rem;
}

.formset .helper-text {
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
    padding-top: 0.25rem;
}

input.is-invalid,
textarea.is-invalid,
div.custom-dropdown-field.is-invalid {
    border-color: var(--invalid-color);
}

.formset .error-message {
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--invalid-color);
    padding-top: 0.25rem;
}

.left-checkbox {
    display: flex;
    align-items: center;
    padding: 0.2rem;
}

.left-checkbox input, .left-checkbox label {
    flex-grow: 0;
    cursor: pointer;
}

.left-checkbox input[type=checkbox] {
    margin-right: 0.5rem;
}

.column-selector-group {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.custom-dropdown-container {
    position: relative;
}

.custom-dropdown-field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-dropdown {
    position: absolute;
    z-index: 2;
    background-color: var(--background-color-white);
    display: inline-flex;
    margin-top: 0.3rem;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px #00000004, 0 2px 4px #00000006, 0 3px 6px #0000000d, 0 4px 8px #00000013, 0 5px 10px #0000001a;
}

.custom-dropdown.dropdown-above {
    bottom: 100%;
    margin-bottom: 0.3rem;
}

.custom-dropdown-list {
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    border-radius: 0.2rem;
    overflow-y: auto;
}

.custom-dropdown-search {
    margin-bottom: 0.5rem;
}

.custom-dropdown-item,
.custom-dropdown-item-no-options {
    padding: 0.5rem 0.4rem;
    font-family: var(--header-font-regular);
    font-size: var(--normal-font-size);
}

.custom-dropdown-group-title {
    padding: 0.75rem 0.4rem 0.25rem 0.4rem;
    font-family: var(--header-font-medium);
    font-size: var(--normal-font-size);
}

.custom-dropdown-group-title.first {
    padding-top: 0.25rem;
}

.custom-dropdown-item .subtitle {
    font-size: var(--small-font-size);
    font-family: var(--header-font-regular);
    color: var(--text-color-lightgray);
    margin-top: 0.4rem;
}

.custom-dropdown-item.active {
    font-family: var(--header-font-medium);
}

.custom-dropdown-placeholder {
    color: var(--text-color-placeholder);
}

.timefield-container .custom-dropdown-item.active {
    background-color: var(--menu-hover-color);
    color: var(--secondary-button-text-color);
    font-family: var(--header-font-medium);
    border-radius: 0.2rem;
}

.timefield-container .custom-dropdown-item.selected {
    background-color: var(--primary-color);
    color: var(--primary-button-text-color);
    font-family: var(--header-font-medium);
    border-radius: 0.2rem;
}

.timefield-container input:disabled,
.dateinput input:disabled {
    cursor: not-allowed;
    background-color: var(--disabled-color);
    box-shadow: unset;
    color: var(--text-color-placeholder);
}

.custom-dropdown-item:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
    border-radius: 0.2rem;
}

.custom-dropdown-field {
    .colored-option {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 100%;
        
        &.soft-blue {
            background-color: var(--soft-blue);
        }
        
        &.soft-dark-blue {
            background-color: var(--soft-dark-blue);
        }
        
        &.soft-red {
            background-color: var(--soft-red);
        }
        
        &.soft-orange {
            background-color: var(--soft-orange);
        }
        
        &.soft-yellow {
            background-color: var(--soft-yellow);
        }
        
        &.soft-green {
            background-color: var(--soft-green);
        }
        
        &.soft-dark-green {
            background-color: var(--soft-dark-green);
        }
        
        &.soft-gray {
            background-color: var(--soft-gray);
        }
        
        &.soft-dark-gray {
            background-color: var(--soft-dark-gray);
        }
        
        &.soft-purple {
            background-color: var(--soft-purple);
        }
        
        &.soft-lavender {
            background-color: var(--soft-lavender);
        }
        
        &.soft-beige {
            background-color: var(--soft-beige);
        }
    }
}

.custom-dropdown-item {
    &.colored-option {
        margin-bottom: 0.5rem;
        height: 0.45rem;
        width: 0.65rem;
        margin-left: 0.4rem; 
        border-radius: 100%;

        &:last-of-type {
            margin-bottom: 0rem;
        } 

        &.soft-blue {
            background-color: var(--soft-blue);
        }
        
        &.soft-dark-blue {
            background-color: var(--soft-dark-blue);
        }
        
        &.soft-red {
            background-color: var(--soft-red);
        }
        
        &.soft-orange {
            background-color: var(--soft-orange);
        }
        
        &.soft-yellow {
            background-color: var(--soft-yellow);
        }
        
        &.soft-green {
            background-color: var(--soft-green);
        }
        
        &.soft-dark-green {
            background-color: var(--soft-dark-green);
        }
        
        &.soft-gray {
            background-color: var(--soft-gray);
        }
        
        &.soft-dark-gray {
            background-color: var(--soft-dark-gray);
        }
        
        &.soft-purple {
            background-color: var(--soft-purple);
        }
        
        &.soft-lavender {
            background-color: var(--soft-lavender);
        }
        
        &.soft-beige {
            background-color: var(--soft-beige);
        }

        .custom-dropdown-selected-icon {
            font-size: 1.1rem;
            margin-top: -0.3rem;
            float: left;
            padding-left: 1.5rem;
        }
    }
}

.custom-dropdown-selected-icon {
    float: right;
    padding-right: 0.5rem;
    margin-top: -0.2rem;
    color: var(--primary-color);
    font-size: 1.2rem;
}

.custom-dropdown-caret {
    margin-left: auto;
    float: right;
    padding-right: 0.5rem;
    padding-top: 0.05rem;
    align-items: center;
    transition: transform 0.3s ease;
}

.searchselect-container {
    position: relative;
    // display: flex; // Commented out, interfereres with dropdownPosition
    align-items: center;
}

.searchselect-container textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
}

.searchselect-container .loader {
    position: absolute;
    right: 0.6rem;
    top: 0.45rem;
    width: 16px;
    height: 16px;
    border: 3px solid #ffffff;
    border-top: 3px solid #bababa;
}

.live-edit-field .searchselect-container .loader {
    margin-top: 0;
}

.custom-dropdown-container .clear-button,
.searchselect-container .clear-button {
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.3rem;
    color: var(--text-color-lightgray);
    cursor: pointer;
}

.live-edit-field .custom-dropdown-container .clear-button,
.live-edit-field .searchselect-container .clear-button {
    position: absolute;
    right: 0.6rem;
    top: 51%;
    transform: translateY(-50%);
    font-size: 1.3rem;
    color: var(--text-color-lightgray);
    cursor: pointer;
}

.clear-button:hover {
    color: var(--primary-text-color);
}

.searchselect-container .custom-dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    border-radius: 0.2rem;
}

.searchselect-container .custom-dropdown-list {
    overflow-y: auto;
    padding: unset;
    border: unset;
}

.searchselect-options,
.searchselect-add {
    padding: 0.5rem;
}

.searchselect-add {
    position: relative;
}

.searchselect-add .add-label {
    padding: 0.5rem 0.4rem;
    color: var(--primary-color);
    font-family: var(--header-font-medium);
    font-size: var(--normal-font-size);
}

.searchselect-add .add-label:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
    border-radius: 0.2rem;
}

.searchselect-add:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0.5rem;
    right: 0.5rem;
    height: 1px;
    background: rgba(0, 0, 0, 0.10);
}

.searchselect-options .limit-message {
    padding: 0rem 0.5rem 0 0.5rem;
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
}

.large-width .inline-form {
    width: 640px;   
}

.medium-width .inline-form {
    width: 480px;
}

.align-center .inline-form {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.inline-form .formfield {
    margin-bottom: 0.5rem;
}

.inline-form {
    padding: 1rem;
}

.inline-form .tabs-field.icon-only button:first-child {
    margin-left: unset;
}

.inline-form-loader {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}

.inline-form-loader .loader {
    border: 2px solid #fff;
    border-top: 3px solid #bababa;
    margin-left: 0;
}

.inline-form .separator {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--text-color-lightgray);
    padding-top: 0.65rem;
}

.inline-form .datetime-row {
    display: flex;
    align-items: top;
    gap: 0.5rem;
    width: 100%;
}

.inline-form .form-information p {
    font-size: var(--small-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
    margin-top: 0;
    margin-bottom: 0;
}

.inline-form .form-information p:not(:first-child) {
    padding-top: 0.15rem;
}

textarea.note-field {
    background-color: var(--background-color-note);
    box-shadow: unset;
    border-radius: 0.2rem;
    resize: none;
}

.multiselect-selected-items {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.add-edit-form .multiselect-selected-items {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.multiselect-selected-item {
    border-radius: 0.2rem;
    background-color: var(--primary-color);
    color: var(--primary-button-text-color);
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    cursor: default;
}

.multiselect-item-remove {
    padding-left: 0.2rem;
    color: var(--primary-button-text-color);
    cursor: pointer;
}

.coloured-dropdown-items {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.coloured-dropdown-item {
    border-radius: 0.2rem;
    background-color: var(--primary-color);
    font-family: var(--header-font-regular);
    font-size: var(--normal-font-size);
    color: var(--primary-button-text-color);
    padding: 0.35rem 0.4rem 0.35rem 0.4rem;
    cursor: pointer;
}

.coloured-dropdown-item:hover {
    opacity: 0.9;
}

.checkbox-label:hover {
    cursor: pointer;
}

.checkbox-field label {
    padding-bottom: unset;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkbox-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.checkbox-item.left {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.label-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.label-description-container .option-label.header {
    font-family: var(--main-font-bold);
    /* font-size: var(--small-font-size); */
}

.checbkox-item.right .label-description-container {
    padding-right: 1rem;
}

.checkbox-item.left .label-description-container {
    text-align: left;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
}

.checkbox-container label,
.checkbox-container p {
    margin: 0;
}

.checkbox-container label + p {
    margin-top: 0.1rem;
}

.hidden-checkbox {
    appearance: none;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox {
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--background-color-white);
    box-shadow: inset 0 2px #0000000a;
    border: 1.5px solid var(--border-color);
    border-radius: 0.2rem;
    cursor: pointer;
    position: relative;
}

.custom-checkbox.left {
    margin-right: 0.5rem;
}

.custom-checkbox.checked {
    background-color: var(--primary-color);
    box-shadow: unset;
    border: 1.5px solid var(--primary-color);
}

.checkbox-check {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.4rem;
    height: 0.4rem;
    transform: translate(-50%, -50%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-check:focus {
    outline: none;
    box-shadow: none;
}

.checkbox-item.left .label-description-container {
    order: 2;
    text-align: left;
}

.checkbox-field .label-description-container .option-description {
    font-family: var(--main-font-light);
    /* font-size: var(--small-font-size); */
}

.checkbox-item.left .checkbox-wrapper {
    order: 1;
}

.divider {
    border-top: 0.5px solid var(--border-color);
}

.formfield .field-header {
    margin-top: 1rem;
    margin-bottom: -0.2rem;
}

.field-header p.description {
    margin-top: 0.3rem;
    margin-bottom: 0;
}

.formfield .field-description p {
    margin-top: 0;
    margin-bottom: 0;
}

.add-edit-form .two-column .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 1.5rem;
}

.add-edit-form .two-column .checkbox-item {
    flex: 0 0 calc(50% - 1.5rem);
}

.checkbox-field.disabled .custom-checkbox {
    opacity: 0.5;
    cursor: not-allowed;
}

.checkbox-field.disabled label,
.checkbox-field.disabled .option-label,
.checkbox-field.disabled .option-description,
.formfield .field-header.disabled .header,
.formfield .field-header.disabled .description {
    color: var(--text-color-lightgray);
}

.checkbox-field.disabled label,
.checkbox-field.disabled .option-label {
    cursor: not-allowed;
}

.radio-option {
    display: flex;
}

.radio-option input[type="radio"] {
    margin-top: unset;
}

.radio-option label {
    cursor: pointer;
}

.datepicker-field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// Image field styling
.imagefield {
    .dropzone {
        border: 1px dashed var(--border-color);
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--text-color-placeholder);
        padding-right: 0.6rem;
        padding-left: 0.6rem;
    
        &.hover {
            background-color: var(--list-hover-color);
        }
    
        input[type='file'] {
            display: none;
        }    
    }
    
    .edit-image {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    
        .reactEasyCrop_Container {
            position: relative;
            width: 200px;
            height: 200px;
        }

        .icon-button {
            margin-left: unset;
        }
    }
    
    .current-image {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        &.horizontal {
            flex-direction: column;

            .image {
                order: 1;
                margin-top: unset;
            }

            .details {
                order: 2;
            }
        }

        .details {
            .image-name {
                font-family: var(--header-font-regular);
                font-family: var(--main-font-regular);
                font-size: var(--small-font-size);
                color: var(--primary-text-color);
            }

            .remove-button {
                font-family: var(--main-font-bold);
                color: var(--primary-color);
                font-size: var(--small-font-size);
                cursor: pointer;
            }
        }

        .image {
            overflow: hidden;
            background-size: cover;
            background-position: center;
            position: relative;
            margin-top: -19px;

            &.smallCircle {
                width: 55px;
                height: 55px;
                border-radius: 50%;
            }

            &.bigCircle {
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }

            &.landscape, &.portrait {
                width: 100%;
                min-height: 150px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center; 
                border-radius: 0.2rem;
                margin-top: 0.4rem;
            }

            .remove-button {
                color: var(--text-color-white);
                font-size: 1.5rem;
                margin-top: 0.2rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: none;
            }

            &:hover {
                opacity: 0.5;
                cursor: pointer;
        
                .remove-button {
                    opacity: 1;
                    display: block;
                }
            }
        }
    }
}