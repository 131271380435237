/* 
 * quill-customized.css 
 * Tryed to customize the snow style of the React Quill plugin.
 */

 @import './global-variables.scss';

 /* Put the styling buttons below the form */
 .quill {
     display: flex;
     flex-direction: column-reverse;
 }
 
 /* Insert own styling to the form */
 .quill .ql-container {
     font-family: var(--header-font-regular);
     font-size: var(--normal-font-size);
     color: var(--primary-text-color);
     line-height: normal;
 }
 
 /* Styling of format types */
 .quill .ql-snow .ql-editor h1 {
     font-size: 1.125rem;
     font-family: var(--header-font-medium);
     font-weight: 400;
 }
 
 .quill .ql-snow .ql-editor h2 {
     font-size: 1.05rem;
     font-family: var(--header-font-regular);
     font-weight: 100;
 }
 
 .quill .ql-snow .ql-editor p {
     font-family: var(--main-font-regular);
     font-size: var(--normal-font-size);
 }
 
 .quill .ql-snow .ql-editor p strong {
     font-family: var(--main-font-bold);
     font-size: var(--normal-font-size);
     font-weight: 400;
 }
 
 /* Change link save and remove color */
 .quill .ql-snow a {
     color: var(--primary-color);
 }
 
 /* Change padding of lists */
 .quill .ql-snow .ql-editor ol,
 .quill .ql-snow .ql-editor ul {
     padding-left: 1.5rem;
 }
 
 .ql-editor ol li:not(.ql-direction-rtl), 
 .ql-editor ul li:not(.ql-direction-rtl) {
     padding-left: unset;
 }
 
 /* Change padding of indents */
 .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
     padding-left: 1.5rem !important;
 }
 
 .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
     padding-left: 3rem !important;
 }
 
 .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
     padding-left: 4.5rem !important;
 }
 
 .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
     padding-left: 6rem !important;
 }
 
 .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
     padding-left: 7.5rem !important;
 }
 
 .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
     padding-left: 9rem !important;
 }
 
 .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
     padding-left: 10.5rem !important;
 }
 
 .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
     padding-left: 12rem !important;
 }
 
 /* Field styling */
 .edit-mode .ql-editor {
     padding-top: 0.4rem;
     padding-bottom: 0.4rem;
     padding-left: 0.6rem;
     padding-right: 0.6rem;
     line-height: 1.2rem;
     white-space: pre-wrap;
 }
 
 .note-form .ql-editor {
     padding: 0.25rem 0 0 0;
     margin-left: 0.5rem;
     min-height: 4rem;
 }
 
 .note .note-form .ql-editor {
     margin-left: 0;
     min-height: unset;
 }
 
 .edit-mode .ql-toolbar.ql-snow + .ql-container.ql-snow {
     border: 1px solid var(--border-color);
     border-bottom: 0;
     box-shadow: inset 0 2px #0000000a;
     border-top-left-radius: 0.3rem;
     border-top-right-radius: 0.3rem;
 }
 
 .note-form .ql-toolbar.ql-snow + .ql-container.ql-snow {
     border: none;
 }
 
 /* Add a colored border when the field is active */
 .edit-mode .focused div.ql-toolbar.ql-snow + .ql-container.ql-snow {
     border: 1px solid var(--primary-color);
     border-bottom: 0;
 }
 
 .edit-mode .focused div.ql-toolbar.ql-snow {
     border: 1px solid var(--primary-color);
     border-top: 0;
 }
 
 /* Placeholder styling */
 .edit-mode .ql-editor.ql-blank::before {
     left: 0.6rem;
     right: 0.6rem;
     font-style: unset;
     color: #757575;
 }
 
 /* Toolbar styling */
 .edit-mode .ql-toolbar.ql-snow {
     border: 1px solid var(--border-color);
     border-top: 0;
     border-bottom-left-radius: 0.3rem;
     border-bottom-right-radius: 0.3rem;
     padding: 0.25rem;
 }
 
 .note-form .ql-toolbar.ql-snow {
     border: none;
     padding: 0.5rem 0 0.25rem 0;
 }
 
 .edit-mode .ql-toolbar.ql-snow .ql-formats,
 .note-form .ql-toolbar.ql-snow .ql-formats {
     margin-right: 0.5rem;
 }
 
 /* Only show the toolbar when the editor is focused, give it an fade effect */
 .quill .ql-toolbar.ql-snow button {
     transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
 }
 
 .quill.hide-toolbar .ql-toolbar.ql-snow button {
     opacity: 0;
     visibility: hidden;
     transition-delay: 0s, 0s;
 }
 
 .quill .ql-toolbar.ql-snow button {
     opacity: 1;
     visibility: visible;
     transition-delay: 0s, 0s;
 }
 
 /* Put the toolbar below the form */
 .quill.note-field .ql-toolbar.ql-snow + .ql-container.ql-snow,
 .quill.note-field .ql-toolbar.ql-snow {
     background-color: var(--background-color-note);
     box-shadow: unset;
 }
 
 /* Change color of toolbar buttons */
 .quill .ql-snow .ql-stroke {
     stroke: var(--text-color-lightgray);
 }
 
 .quill .ql-snow .ql-fill {
     fill: var(--text-color-lightgray);
 }
 
 /* Change color of toolbar button hover */
 .ql-snow.ql-toolbar button:hover,
 .ql-snow .ql-toolbar button:hover,
 .ql-snow.ql-toolbar button:focus,
 .ql-snow .ql-toolbar button:focus,
 .ql-snow.ql-toolbar button.ql-active,
 .ql-snow .ql-toolbar button.ql-active,
 .ql-snow.ql-toolbar .ql-picker-label:hover,
 .ql-snow .ql-toolbar .ql-picker-label:hover,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active,
 .ql-snow.ql-toolbar .ql-picker-item:hover,
 .ql-snow .ql-toolbar .ql-picker-item:hover,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
   color: var(--secondary-button-text-color) !important;
 }
 .ql-snow.ql-toolbar button:hover .ql-fill,
 .ql-snow .ql-toolbar button:hover .ql-fill,
 .ql-snow.ql-toolbar button:focus .ql-fill,
 .ql-snow .ql-toolbar button:focus .ql-fill,
 .ql-snow.ql-toolbar button.ql-active .ql-fill,
 .ql-snow .ql-toolbar button.ql-active .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
   fill: var(--secondary-button-text-color) !important;
 }
 .ql-snow.ql-toolbar button:hover .ql-stroke,
 .ql-snow .ql-toolbar button:hover .ql-stroke,
 .ql-snow.ql-toolbar button:focus .ql-stroke,
 .ql-snow .ql-toolbar button:focus .ql-stroke,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
 .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
 .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
   stroke: var(--secondary-button-text-color) !important;
 }