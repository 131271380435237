/* detailpage.css */

@import './global-variables.scss';

// General detail modal styling
.detailmodal {
    .header {
        padding: 1.5rem 1.5rem 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        
        .title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .full-width-buttons {
            margin-top: 0.75rem;
            justify-content: space-between;

            .left-buttons,
            .right-buttons {
                display: flex;
                gap: 0.5rem;
            }
        }

        .status-bar {
            margin-top: 1rem;
            height: 2.5rem;
        }
    }

    .card {
        background-color: unset;
        box-shadow: unset;
        margin-bottom: unset;
        padding: 1rem 1.5rem 1rem 1.5rem;
    }

    .fieldscard, .appointmentscard {
        padding: 1rem 1.2rem 1rem 1.2rem;

        // In case of detail modals, show view mode more condensed
        .horizontal-alignment {

            label, .view-mode {
                padding: 0.2rem 0.3rem 0.2rem 0.3rem;
            }
        }
    }

    .summary {
        width: 25%;
        background-color: var(--status-bar-color);
        
        .wrapper {
            padding: 1.5rem 0.5rem 0.5rem 0.5rem;

            .summary-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 0.5rem;
                padding-bottom: 2.5rem;

                .summary-details {
                    padding-top: 0.5rem;

                    .badge-container {
                        justify-content: center;
                    }
    
                    .detail-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-family: var(--main-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--small-font-size);
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                    }
                }
            }

            .summary-section {
                margin-bottom: 2rem;

                h6 {
                    text-align: center;
                    font-size: var(--small-font-size);
                    padding-bottom: 0.25rem;
                }
            }

            .linked-items-list {
                display: flex;
                flex-direction: column;
                align-items: center;

                .linked-item {
                    display: flex;
                    align-items: center;
                    padding-bottom: 0.2rem;

                    .item-name {
                        font-family: var(--main-font-regular);
                        color: var(--primary-text-color);
                        font-size: var(--normal-font-size);
                    }

                    .status-badge {
                        width: unset;
                        padding: 0.2rem 0.3rem;
                        margin-left: 0.2rem;
                        line-height: 1;
                    }

                    &:hover {
                        cursor: pointer;
                        
                        .item-name {
                            text-decoration: underline;
                            color: var(--primary-color);
                        }
                    }


                }

            }
        }
    }
}

// Delete modal
.delete-modal,
.warning-modal {  
    .warning-icon {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        color: var(--warning-color);
    }

    h3 {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }

    .button-row {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 1rem;

        .confirm-delete,
        .approve {
            background-color: var(--warning-color);
        }
    }
}

// Schedule job modal styling
.schedule-job-modal {
    padding: 0 0.5rem 0 0.5rem;

    .header {
        margin-bottom: 1.5rem;
    }

    .appointment-row {
        display: grid;
        grid-template-columns: 7fr auto 3fr;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4rem;

        .fields > div {
            .tooltip-icon {
                padding-left: 0.3rem;
            }
        }

        .fields > div:not(:last-child) {
            margin-bottom: 0.7rem;
            gap: 0.5rem;
        }

        .date-time-row {
            display: grid;
            grid-template-columns: 3fr 2fr 2fr 3fr;

            &.whole-day {
                grid-template-columns: 3fr 3fr 2fr 2fr;
            }

            .whole-day {
                display: flex;
                margin-top: 22px;

                .checkbox-label.disabled {
                    cursor: not-allowed;
                    color: var(--text-color-placeholder);
                }

                .custom-checkbox.disabled {
                    cursor: not-allowed;
                }
            }
        }

        .timeblock-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .separator {
            margin: 0 2rem 0 2rem;
            border-left: 1px solid var(--border-color);
        }

        .buttons {
            display: flex;
            align-items: center;
        }
    }

    .appointment-row:last-child {
        padding-bottom: 0;
    }

    .add-appointment {
        font-family: var(--main-font-bold);
        color: var(--text-color-lightgray);
        font-size: var(--normal-font-size);
        padding: 0.7rem 0 1.5rem 0;

        &:hover {
            cursor: pointer;
            color: var(--primary-color);
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding-top: 2.5rem;

        .email-checkbox {
            display: flex;
            align-items: center;
        }

        .checkbox-label {
            cursor: pointer;
        }

        .buttons-right {
            display: flex;
            float: right;

            button {
                margin-left: 0.5rem;
            }   
        }
    }
}

.release-modal {
    .header {
        display: flex;
        justify-content: space-between;

        .right-header {
            display: flex;
            flex-direction: row;

            .details {
                display: flex;
                padding-top: 0.25rem;
            }   
        }
    }

    .content {
        .description,
        .change-log {
            margin-top: 2rem;
        }
    }
}

.import-details-modal {
    .header {
        display: flex;
        justify-content: space-between;

        .right-header {
            display: flex;
            flex-direction: row;

            .details {
                display: flex;
                padding-top: 0.25rem;
            }   
        }
    }

    .content {
        .imported-rows {
            margin-top: 2rem;
        }

        .failed-rows {
            margin-top: 2rem;
        }
    }
}

.import-modal {
    .dropzone {
        border: 1.5px dashed var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--text-color-placeholder);
        padding-right: 0.6rem;
        padding-left: 0.6rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        height: unset;
    
        &.hover {
            background-color: var(--list-hover-color);
        }

        input[type='file'] {
            display: none;
        }
    }
}