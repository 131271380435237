/* alert.css */

@import './global-variables.scss';

.alert {
    border-radius: 0.3rem;
    border: 1px solid transparent;
    font-family: var(--header-font-regular);
    font-size: var(--larger-font-size);
}

.floating-alert {
    padding: 0.75rem 0.8rem;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    z-index: 1005;
}

.form-alert {
    padding: 0.6rem;
    border-radius: 0.3rem;
    margin-bottom: 0.8rem;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #fff3cd;
}

.close-alert {
    cursor: pointer;
    text-align: right;
    font-size: 0.875rem;
    padding-left: 0.3rem;
}

.page-alert {
    text-align: center;
    width: 80%;
}

.page-alert .alert {
    padding: 1rem;
}

.alert .title {
    font-size: var(--h4-font-size);
    font-family: var(--header-font-medium);
    font-weight: 400;
    padding-bottom: 0.25rem;
}