/* timeline.css */

@import './global-variables.scss';

.timeline .timeline-loader {
    display: flex;
    justify-content: center;
    padding: 1rem;
    align-items: center;
}

.timeline .timeline-loader .loader {
    border: 2px solid var(--background-color);
    border-top: 3px solid #bababa;
}

.timeline .buttonbar {
    background-color: var(--background-color-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    position: relative;
    margin-bottom: 2rem;
}

.timeline > .buttonbar > ul {
    padding: 1rem 1rem 0.95rem 1rem;
    display: flex;
    position: relative;
}

.timeline > .buttonbar > ul::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    background: rgba(0, 0, 0, 0.10);
}

.timeline > .buttonbar > ul > li {
    font-size: var(--h6-font-size);
    font-family: var(--header-font-medium);
    color: var(--primary-text-color);
    font-weight: 400;
}

.timeline > .buttonbar > ul > li:not(:first-child) {
    padding-left: 1.5rem;
}

.timeline > .buttonbar > ul > li:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.timeline > .buttonbar > ul > li.active {
    color: var(--primary-color);
    cursor: default;
}

.timeline .buttonbar .blank-note {
    background-color: var(--background-color-note);
    height: 2.9rem;
    cursor: pointer;
}

.timeline .note-form {
    background-color: var(--background-color-note);
    padding: 0.5rem;
    cursor: text;
}

.timeline .note-form .button-row {
    justify-content: space-between;
    margin-top: -2.25rem;
}

.timeline .note-form .error-message {
    padding-top: 0.5rem;
    color: var(--text-color-lightgray)
}

.timeline .formset .buttons-right button {
    margin-left: 0.3rem;
}

.timeline-item {
    display: flex;
    align-items: center;
}

.timeline-item {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.timeline-item.pinned {
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.marker-container {
    display: flex;
    width: 3.75rem;
}

.marker-container.hide {
    display: none;
}

.small-marker {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: var(--background-color-white);
    border: 1px solid var(--border-color);
    margin-left: 0.75rem;
}

.big-marker {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 100%;
    background: var(--background-color-white);
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-lightgray);
    margin-right: 1rem;
}

.big-marker .note-icon {
    font-size: 20px;
}

.update-content {
    flex-grow: 1;
}

.note-content {
    flex-grow: 1;
    background-color: var(--background-color-note);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    padding: 0.75rem;
    width: 100%;
}

.note-content.editing .note-form {
    padding: 0rem;
}

.note-content.editing .note-form textarea {
    padding: 0rem;
    font-family: var(--main-font-regular);
}

.note-content .note-form-loader {
    display: flex;
    padding: 0.25rem 1rem 0.25rem 1rem;
    justify-content: center;
    align-items: center;
}

.note-form-loader .loader {
    border: 2px solid var(--background-color-note);
    border-top: 3px solid #bababa;
}

.note-form-loader .loader.hide {
    visibility: hidden;
}

.note-text,
.update-text {
    margin: unset;
}

.note-text {
    padding-top: 0.25rem;
}

/* Styling of html styling inside of notes */
.note-text strong {
    font-family: var(--main-font-bold);
}

.note-text p,
.note-text ol {
    margin-top: unset;
    margin-bottom: unset;
}

.note-text a {
    color: var(--primary-color);
    text-decoration: underline;
}

.note-text ol,
.note-text ul {
    padding-inline-start: 0;
    list-style-position: inside;
}

.note-text ol li {
    list-style-type: decimal;
}

.note-text ol li.ql-indent-1 {
    list-style-type: lower-alpha;
    padding-left: 1.5rem;
}

.note-text ol li.ql-indent-2 {
    list-style-type: lower-roman;
    padding-left: 3rem;
}

.note-text ul li {
    list-style-type: disc;
}

.note-text .ql-indent-1 {
    padding-left: 1.5rem;
}

.note-text .ql-indent-2 {
    padding-left: 3rem;
}

.note-text .ql-indent-3 {
    padding-left: 4.5rem;
}

.note-text .ql-indent-4 {
    padding-left: 6rem;
}

.note-text .ql-indent-5 {
    padding-left: 7.5rem;
}

.note-text .ql-indent-6 {
    padding-left: 9rem;
}

.note-text .ql-indent-7 {
    padding-left: 10.5rem;
}

.note-text .ql-indent-8 {
    padding-left: 12rem;
}

.update-arrow {
    height: 0.65rem;
    color: var(--secondary-button-text-color);
    vertical-align: unset;
}

.update-info {
    font-size: var(--smaller-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
    display: flex;
    align-items: center;
    margin-top: 0.1rem;
    margin-bottom: unset;
}

.note-info {
    font-size: var(--smaller-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: unset;
    margin-bottom: 0.1rem;
}

.note-info .left-items {
    text-align: left;
}

.note-info .right-items div {
    padding-left: 0.2rem;
}

.expand-icon:hover,
.pin-icon:hover,
.delete-icon:hover,
.edit-icon:hover {
    cursor: pointer;
    color: var(--primary-text-color)
}

.form-container {
    transition: opacity 0.3s;
}

.form-container.hide {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.form-container.show {
    opacity: 1;
    pointer-events: auto;
    position: relative;
}

.task-content {
    flex-grow: 1;
    background-color: var(--background-color-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
    width: 100%;
}

.task-content.editing .note-form {
    padding: 0rem;
}

.task-content.editing .note-form textarea {
    padding: 0rem;
    font-family: var(--main-font-regular);
}

.task-header {
    padding: 0.75rem;
}

.task-note {
    flex-grow: 1;
    background-color: var(--background-color-note);
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
}

.task-note .left-items .note-text {
    text-align: left;
    padding-top: unset;
}

.task-note .right-items {
    font-size: var(--smaller-font-size);
    font-family: var(--main-font-regular);
    color: var(--text-color-lightgray);
}

.task-title-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.task-title-wrapper .check-button-wrapper {
    margin-right: 0.35rem
}

.task-title-wrapper .check-button {
    text-align: left;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 2px solid var(--border-color);
    background-color: var(--background-color-white);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.task-title-wrapper .check-button:hover {
    border: 2px solid var(--dark-green);
}

.task-title-wrapper .check-button.checked {
    background-color: var(--positive-green);
    border: 2px solid var(--positive-green);
}

.task-title-wrapper .check-button.checked img {
    height: 0.6rem;
}

.task-title-wrapper .task-title {
    margin: unset;
    font-size: var(--large-font-size);
    font-family: var(--header-font-regular);
}

.task-title-wrapper .task-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.task-info {
    font-family: var(--main-font-regular);
    font-size: var(--normal-font-size);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
}

.task-info .left-items {
    text-align: left;
    font-size: var(--small-font-size);
    color: var(--primary-text-color);
}

.task-info .left-items .today {
    color: var(--positive-green);
    font-family: var(--main-font-bold);
}

.task-info .left-items .overdue {
    color: var(--overdue);
    font-family: var(--main-font-bold);
}

.task-info .left-items .assignee {
    color: var(--primary-text-color);
}

.task-info .right-items div {
    padding-left: 0.2rem;
    color: var(--text-color-lightgray);
    font-size: var(--smaller-font-size);
}