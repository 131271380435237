/* button.css */

@import './global-variables.scss';

.button-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.button {
    font-size: var(--normal-font-size);
    font-family: var(--header-font-medium);
    border-style: none;
    cursor: pointer;
    appearance: normal;

    &.button-primary {
        background-color: var(--primary-color);
        border-color: 1px solid var(--primary-color);
        --box-shadow-color: var(--primary-shadow-color);
        box-shadow: 0 2px var(--primary-shadow-color);
        color: var(--primary-button-text-color);
    
        &:hover,
        &.button-active {
            background-color: var(--primary-color-hover);
        }

        &.button-active {
            box-shadow: none;
        }
    }

    &.button-secondary {
        background-color: var(--secondary-color);
        border-color: 1px solid var(--secondary-color);
        --box-shadow-color: var(--secondary-shadow-color);
        color: var(--secondary-button-text-color);
        box-shadow: 0 2px var(--secondary-shadow-color);
    
        &:hover,
        &.button-active {
            background-color: var(--secondary-color-hover);
        }
    
        &:disabled{
            background-color: var(--secondary-color);
        }

        &.button-active {
            box-shadow: none;
        }
    }

    /* Button coloring based on conditions */
    &.scheduled_appointment {
        background-color: var(--scheduled_appointment);
        border-color: 1px solid var(--scheduled_appointment);
        --box-shadow-color: #2f3648;
        box-shadow: 0 2px #2f3648;
    
        &:hover {
            background-color: #3b4357;
        }
    }

    &.one_open_purchase {
        background-color: var(--one_open_purchase);
        border-color: 1px solid var(--one_open_purchase);
        --box-shadow-color: #561572;
        box-shadow: 0 2px #561572;
    
        &:hover {
            background-color: #8c42b5;
        }
    }

    &.won,
    &.accepted {
        background-color: var(--general-green);
        border-color: 1px solid var(--general-green);
        --box-shadow-color: #1d8740;
        box-shadow: 0 2px #1d8740;
    
        &:hover {
            background-color: #44d06b;
        }
    }

    &.lost,
    &.rejected {
        background-color: var(--general-red);
        border-color: 1px solid var(--general-red);
        --box-shadow-color: #a52f2f;
        box-shadow: 0 2px #a52f2f;
    
        &:hover {
            background-color: #f06565;
        }
    }


    &.finished {
        background-color: var(--finished);
        border-color: 1px solid var(--finished);
        --box-shadow-color: #247115;
        box-shadow: 0 2px #247115;
    
        &:hover {
            background-color: #35a122;
        }
    }
}

.icon-button {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    font-size: var(--small-font-size);

    .icon-button-label {
        padding-left: 0.4rem;
    }

    // Commented out, try to replace it with gap: 0.3rem;
    // &.button-extra-small {
    //     margin-left: 0.3rem;
    // }
}

.button-large {
    width: 100%;
    border-radius: 0.3rem;
    height: 2.5rem;
}

.button-medium {
    border-radius: 0.3rem;
    height: 2.5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.button-small {
    border-radius: 0.3rem;
    height: 2rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    position: relative;
}

.button-extra-small {
    border-radius: 0.3rem;
    height: 1.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    position: relative;
}

.button:active {
    transform: translateY(2px);
    box-shadow: 0 0px transparent;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
}

.button-active {
    transform: translateY(2px);
    box-shadow: none;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
}

button:disabled.button-secondary {
    color: var(--secondary-shadow-color);
    cursor: not-allowed;
}

button:disabled.button-secondary:active {
    transform: none;
    --box-shadow-color: var(--secondary-shadow-color);
    box-shadow: 0 2px var(--secondary-shadow-color);
}

button:focus {
    outline: none;
}

/* Changes button style when loading */
.button-loading {
    background-color: var(--primary-color-hover);
    cursor: not-allowed;
    transform: translateY(2px);
    box-shadow: none;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-loader {
    border: 2px solid var(--primary-color-hover);
    border-top: 3px solid var(--primary-button-text-color);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    transform: translate(-50%, -50%);
    margin-top: 1px;
    animation: spin 1.2s linear infinite;
}

.button-primary {
    .button-loader {
        border: 2px solid var(--primary-color-hover);
        border-top: 3px solid var(--primary-button-text-color);
    }

    &.danger {
        background-color: var(--warning-color);

        &:hover {
            background-color: var(--general-red-hover);
        }
    }
}

.button-secondary {
    .button-loader {
        border: 2px solid var(--secondary-color);
        border-top: 3px solid var(--secondary-button-text-color);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-text {
    transition: opacity 0.3s;
}

/* Hides text when loading. This is needed so that the button keeps the same width */
.text-hidden {
    opacity: 0;
}

.switch-button {
    width: 2.5rem;
    height: 1.4rem;
    background-color: var(--border-color);
    border-radius: 1.75rem;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;
}

.switch-button.on {
    background-color: var(--positive-green);
}
  
.switch-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
  
.switch-button-handle {
    width: 1.15rem;
    height: 1.15rem;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
}

.switch-button.no-transition .switch-button-handle {
    transition: none !important;
}

.switch-button.animate .switch-button-handle {
    transition: left 0.2s;
}

.switch-button.disabled .switch-button-handle {
    background-color: #EEEEEE;
}
  
.switch-button.on .switch-button-handle {
    left: 1.25rem;
}

.switch-button-wrapper {
    margin-right: 0.4rem;
}

.dropdown-button {
    position: relative;
}

.dropdown-button-menu {
    position: absolute;
    margin: 0.5rem 0 0 0.5rem;
    top: 100%;
    left: 0;
    z-index: 3;
    display: block;
    background-color: var(--background-color-white);
    border-radius: 0.5rem;
    box-shadow: 0 0 20px rgba(10, 10, 10, 0.25);

    .dropdown-button-group {
        padding-top: 2rem;
        border-top: 1px solid var(--border-color);
    }

    .dropdown-button-group-title {
        padding: 0 0 0.5rem 1rem;
        font-family: var(--header-font-medium);
        font-size: var(--normal-font-size);
        color: var(--secondary-button-text-color);
    }

    .dropdown-button-item {
        font-family: var(--header-font-regular);
        font-size: var(--normal-font-size);
        color: var(--secondary-button-text-color);
        align-items: center;
        min-height: 2.5rem;
        display: flex;
        white-space: nowrap;
        padding: 0 1rem;
        cursor: pointer;
    
        &.active {
            font-family: var(--header-font-medium);
            color: var(--primary-color);
        }
    }
    
    .dropdown-button-item:not(:first-child) {
        border-top: 1px solid var(--border-color);
    }
    
    .dropdown-button-item:hover {
        background-color: var(--list-hover-color);
        color: var(--primary-color);
    }
    
    .dropdown-button-item:hover:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    .dropdown-button-item:hover:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
}

.date-navigate-container {
    position: relative;
    display: inline-block;

    .datepicker {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0.5rem;
        background-color: var(--background-color-white);
        border-radius: 0.5rem;
        box-shadow: 0 0 20px rgba(10, 10, 10, 0.25);
        z-index: 1000;
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
    }
}

.date-navigate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;

    button.previous, button.date, button.next {
        background-color: var(--secondary-color);
        color: var(--secondary-button-text-color);
        border-color: 1px solid var(--secondary-color);
        --box-shadow-color: var(--secondary-shadow-color);
        box-shadow: 0 2px var(--secondary-shadow-color);
        cursor: pointer;
    }

    button.previous, button.next {
        border-style: none;
        appearance: normal;
        height: 2rem;
        font-size: var(--small-font-size);

        svg {
            margin-top: 0.175rem;
        }
    }

    button.previous {
        padding-left: 0.9rem;
        padding-right: 0.3rem;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }

    button.next {
        padding-left: 0.3rem;
        padding-right: 0.9rem;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    button.date {
        font-size: var(--normal-font-size);
        font-family: var(--header-font-medium);
        border-style: none;
        appearance: normal;
        height: 2rem;
    }

    &:hover {
        background-color: var(--secondary-color-hover);
    }

    &:active {
        transform: translateY(2px);
        box-shadow: 0 0px transparent;
        -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);

        button.previous, button.date, button.next {
            box-shadow: 0 0px transparent;
        }
    }
}

.tabs-button {
    display: flex;

    button {
        border-radius: 0;

        &:first-child {
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
        }

        &:last-child {
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
        }
    }
    
    .active-tab {
        background-color: var(--secondary-color-hover);
        box-shadow: 0 2px var(--secondary-color-hover);
    }
}