/* tooltip.css */

@import './global-variables.scss';

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.3rem;
    background-color: var(--tooltip-color);
    color: var(--primary-button-text-color);
    padding: 0.5rem 0.4rem;
    border-radius: 0.25rem;
    font-family: var(--main-font-regular);
    font-size: var(--small-font-size);
    white-space: pre-wrap;
    visibility: hidden;
    max-width: 200px;
    width: max-content;
}

.icon-button,
.tab-button,
.tooltip-icon {
    position: relative;
    display: inline-block;
}

.icon-button:hover .tooltip,
.tab-button:hover .tooltip,
.tooltip-icon:hover .tooltip  {
    visibility: visible;
    opacity: 1;
}

.tooltip-left {
    left: auto;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 0.3rem;
    bottom: unset;
    margin-bottom: unset;
    margin-top: 0.4rem;
}

.tooltip-right {
    left: 100%;
    right: auto;
    bottom: unset;
    transform: translateY(-50%);
    margin-left: 0.75rem;
    margin-top: 0.4rem;
    z-index: 2;
}

.tooltip-bottom {
    bottom: unset;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0.3rem;
    z-index: 2;
}