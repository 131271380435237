/* wizard.scss */

@import './global-variables.scss';

.wizard {
    width: 100%;
    margin-top: 2.5rem;

    .step-indicator {
            display: flex;
            margin-bottom: 1.5rem;
        
        .step {
            width: 0.75rem;
            height: 0.75rem;
            background-color: var(--menu-hover-color);
            border-radius: 50%;
            margin-right: 0.5rem;

            &.completed {
                background-color: var(--primary-color);
            }
        }
    }

    .wizard-step {
        h2, h3 {
            margin-bottom: 1.5rem;
        }

        .step-content {
            margin: 1.5rem 0 1.5rem 0;

            .option-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                background-color: var(--background-color-white);
                border-top: 1px solid var(--border-color);
        
                .label {
                    margin-left: 1rem;
                    font-size: var(--larger-font-size);
                    font-family: var(--header-font-regular);
                }
        
                .icon {
                    margin-top: 0.125rem;
                    margin-right: 1rem;
                    color: var(--text-color-lightgray)
                }
        
                &:last-child {
                    border-bottom: 1px solid var(--border-color);
                }
        
                &:hover {
                    background-color: var(--list-hover-color);
                    cursor: pointer;
                }
            }
        }
    }

    .button-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
                
        .step-counter {
            font-family: var(--main-font-light);
            font-size: var(--normal-font-size);
            color: var(--text-color-placeholder);
            margin: 0 auto;
            text-align: center;
        }
    }

    .button-footer-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--background-color-white);
        border: 1px solid var(--border-color-light);
        z-index: 1000;
        padding: 1rem 1.5rem;

        .container-filled {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .step-counter {
            font-family: var(--main-font-light);
            font-size: var(--normal-font-size);
            color: var(--text-color-placeholder);
            margin: 0 auto;
            text-align: center;
        }
    }
}

.trial-wizard {
    .checkbox-item {
        background-color: var(--background-color-white);
        border-top: 1px solid var(--border-color);

        &:last-child {
            border-bottom: 1px solid var(--border-color);
        }

        &:hover {
            background-color: var(--list-hover-color);
            cursor: pointer;
        }

        .label-description-container {
            padding: 0.75rem 0 0.75rem 1rem;

            .checkbox-label {
                font-size: var(--larger-font-size);
                font-family: var(--header-font-regular)
            }
        }

        .checkbox-wrapper {
            padding: 0.75rem 1rem 0.75rem 1rem;
        }                
    }
}

// TODO: not used anymore but have been used in the not fully implemented workflow wizard modal
// .wizard-modal {
//     padding: 1rem;
//     display: flex;
//     flex-direction: column;

//     .workflow-wizard {
//         .workflow-section {
//             padding: unset;
//             padding-bottom: 2rem;
//             box-shadow: unset;

//             .formfield {
//                 label {
//                     padding-left: 0;
//                 }

//                 .dropdown-container {
//                     max-width: 40%;
//                 }
//             }
//         }

//         .workflow-section:not(:last-child) {
//             border-bottom: 1px solid var(--border-color);
//             margin-bottom: 2rem; 
//         }
//     }

//     .button-row {
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//     }
// }