/* scheduling.css */

@import './global-variables.scss';

.scheduling-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    .content-left, .content-middle, .content-right {
        display: flex;
        align-items: center;
    }

    .content-left {
        justify-content: flex-start;
    }

    .content-middle {
        justify-content: center;
        flex-grow: 1;
    }

    .content-right {
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }
}

.scheduling-board {

    /*** General scheduling styling ***/
    .calendar {
        .header {

        }

        .event {
            background-color: var(--general-dark-blue);
            font-family: var(--main-font-regular);
            font-size: var(--smaller-font-size);
            color: var(--text-color-white);
            border-radius: 0.25rem;
            z-index: 10;
            cursor: pointer;
            overflow: hidden;
            pointer-events: auto;
            white-space: normal;
            word-wrap: break-word;

            span {
                white-space: unset;
            }

            &:hover {
                opacity: 0.85;
            }


            // Event colors based on status (glossy colors)

            &.reserved {
                background-color: var(--one_reserved_appointment);
            }

            &.planned {
                background-color: var(--general-dark-blue);

                &:hover {
                    background-color: black;
                }
            }

            &.en_route {
                background-color: var(--general-blue);
            }

            &.in_progress {
                background-color: var(--general-yellow);
            }

            &.incomplete {
                background-color: var(--general-orange);
            }

            &.completed {
                background-color: var(--general-green);
            }

            &.finished {
                background-color: var(--general-dark-green);
            }


            // Custom event color (soft colors)

            &.default-custom-color {
                background-color: var(--secondary-color-hover);
                color: var(--secondary-button-text-color);
            }

            &.soft-dark-blue {
                background-color: var(--soft-dark-blue);
            }

            &.soft-blue {
                background-color: var(--soft-blue);
            }

            &.soft-red {
                background-color: var(--soft-red);
            }

            &.soft-orange {
                background-color: var(--soft-orange);
            }

            &.soft-yellow {
                background-color: var(--soft-yellow);
            }

            &.soft-green {
                background-color: var(--soft-green);
            }

            &.soft-dark-green {
                background-color: var(--soft-dark-green);
            }

            &.soft-gray {
                background-color: var(--soft-gray);
            }

            &.soft-dark-gray {
                background-color: var(--soft-dark-gray);
            }

            &.soft-purple {
                background-color: var(--soft-purple);
            }

            &.soft-lavender {
                background-color: var(--soft-lavender);
            }
            
            &.soft-beige {
                background-color: var(--soft-beige);
            }
        }

        .resource-row {
            .name-column {

                .order-icon {
                    display: flex;
                    align-items: center;
                    color: var(--text-color-lightgray);
                    font-size: 0.85rem;
                    display: none;
                }

                &:hover .order-icon {
                    cursor: pointer;
                }

                &:hover .order-icon,
                &:active .order-icon {
                    display: block;

                    .tooltip {
                        z-index: 10;
                    }
    
                    &.visibility-hidden {
                        visibility: hidden;
                    }
                }
            }

            
        }        
    }


    /*** Styling based on the stacked view direction ***/
    &.stacked {
        &.employee_list, &.employee_timeline {
            // Generate grid of scheduling board for the employee_list and
            // employee_timeline views.
            @each $columns in 1, 5, 6, 7 {
                &.grid-#{$columns}-columns {
                    display: grid;
                    grid-template-columns: #{1 + $columns}fr;
                    width: 100%;
                }
    
                &.show-inbox {
                    grid-template-columns: 6fr 2fr;
                }            
            }
        }

        .calendar {
            overflow-x: hidden;

            .header {
                font-family: var(--header-font-medium);
                font-size: var(--normal-font-size);
                border-top: 1px solid var(--border-color);
                border-bottom: 1px solid var(--border-color);
                background-color: var(--background-color-white);
        
                // Generate grid of the header row based on given columns
                @each $columns in 1, 5, 6, 7 {
                    &.grid-#{$columns}-columns {
                        display: grid;
                        grid-template-columns: 200px repeat(#{$columns}, 1fr);
                    }
                }

                .name-column {
                    border-right: 1px solid var(--border-color);
                }

                .day-column:not(:last-child) {
                    border-right: 1px solid var(--border-color);
                }

                .day-column {
                    height: 2.5rem;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    justify-content: center;

                    .date-name {
                        padding-top: 0.25rem;

                        .today {
                            position: relative;
                        }
    
                        .today::after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -5.5px;
                            left: 0px;
                            right: 0px;
                            height: 0.2rem;
                            border-radius: 0.2rem;
                            background-color: var(--primary-color);
                        }
                    }

                    .header-hours {
                        display: flex;
                        margin-top: auto;
                        margin-left: -4px;
                        width: 100%;
                        font-family: var(--header-font-light);
                        font-size: var(--smaller-font-size);
                        color: var(--text-color-lightgray);

                        .header-hour {
                            flex: 1 1 auto;
                            box-sizing: border-box;
                        }

                        .invisible {
                            visibility: hidden;
                        }
                    }
                }
            }
        
            .unassigned-row {
                width: 100%;
                border-bottom: 1px solid var(--border-color);

                // Generate grid of scheduling rows based on given columns
                @each $columns in 1, 5, 6, 7 {
                    &.grid-#{$columns}-columns {
                        display: grid;
                        grid-template-columns: 200px repeat(#{$columns}, 1fr);
                    }
                }
            
                .name-column, .day-column {
                    height: 44px;
                    border-right: 1px solid var(--border-color);
                }
            
                .name-column {
                    display: flex;
                    align-items: center;
                    background-color: var(--background-color-white);
                    font-family: var(--header-font-medium);
                    font-size: var(--normal-font-size);
                    padding-left: 0.5rem;
                }
        
                .day-column {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    width: 100%;
                    border-right: 1px solid var(--border-color);

                    .day-list {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 0.25rem;

                        .event {
                            position: relative;
                            display: flex;
                            align-items: center;
                            height: 1.25rem;
                            padding: 0.25rem;
                        }
                    }
        
                    .time-slot {
                        position: relative;
                        flex: 1 1 auto;
                        box-sizing: border-box;

                        .tooltip {
                            position: absolute;
                            bottom: unset;
                            transform: unset;
                            left: 0;
                            z-index: 9999 !important;
                        }
        
                        &.whole-hour {
                            border-left: 1px solid var(--border-color-light);
                        }
        
                        &:hover {
                            background-color: var(--secondary-color);
                        }

                        &.no-hover {
                            pointer-events: none;
                            background-color: transparent !important;
                        }

                        &.has-event:hover {
                            background-color: transparent;
                        }
                    }
                }
            }

            .resource-group-header {
                min-height: 2rem;
                background-color: var(--background-color-white);
                font-family: var(--header-font-regular);
                font-size: var(--small-font-size);
                display: flex;
                align-items: center;
                padding-left: 0.5rem;
                border-bottom: 1px solid var(--border-color);
            }
        
            .resource-row {
                width: 100%;
                border-bottom: 1px solid var(--border-color);
        
                // Generate grid of scheduling rows based on given columns
                @each $columns in 1, 5, 6, 7 {
                    &.grid-#{$columns}-columns {
                        display: grid;
                        grid-template-columns: 200px repeat(#{$columns}, 1fr);
                    }
                }
        
                .name-column, .day-column {
                    min-height: 2.5rem;
                    border-right: 1px solid var(--border-color);
                }
        
                .name-column {
                    display: flex;
                    align-items: center;
                    background-color: var(--background-color-white);
                    font-family: var(--header-font-medium);
                    font-size: var(--normal-font-size);
                    padding-left: 0.5rem;
        
                    .resource-image {
                        justify-content: start;
                        overflow: hidden;
                        background-size: cover;
                        background-position: center;
                        position: relative;
                        height: 2.5rem;
                        width: 2.5rem;
                        border-radius: 50%;
                        margin-right: 0.25rem;
                    }
        
                    .resource-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-grow: 1;
                        flex-shrink: 1;
        
                        .remark {
                            font-family: var(--main-font-regular);
                            color: var(--primary-text-color);
                            font-size: var(--small-font-size);
                        }
                    }

                    .order-icon {
                        margin-left: auto;
                        margin-right: 0.5rem;
                    }
                }
        
                .day-column {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    width: 100%;

                    .day-list {
                        position: relative;
                        overflow-y: auto;
                        width: 100%;
                        height: 100%;

                        .event {
                            position: relative;
                            display: flex;
                            align-items: center;
                            height: 1.25rem;
                            padding: 0.25rem;
                            margin: 0.25rem 0.25rem 0 0.25rem;

                            .list-event {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
        
                    .time-slot {
                        position: relative;
                        flex: 1 1 auto;
                        box-sizing: border-box;

                        .tooltip {
                            position: absolute;
                            bottom: unset;
                            transform: unset;
                            left: 0;
                            z-index: 9999 !important;
                        }
        
                        &.whole-hour {
                            border-left: 1px solid var(--border-color-light);
                        }
        
                        &:hover {
                            background-color: var(--secondary-color);
                        }

                        &.no-hover {
                            pointer-events: none;
                            background-color: transparent !important;
                        }

                        &.has-event:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }


    /*** Styling based on the columns view direction ***/
    &.columns {
        &.employee_list, &.employee_timeline { 
            display: grid;
            height: 100%;

            &.grid-1-columns {
                .calendar {
                    overflow-y: hidden;
                }
            }
            
            &.show-inbox {
                grid-template-columns: 6fr 2fr;
            }
                    
            .calendar {
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: 1fr;
                box-sizing: border-box;
                overflow-x: auto;
                overflow-y: hidden;

                .header {
                    display: flex;
                    flex-direction: column;
                    width: 3rem;
                    font-family: var(--header-font-medium);
                    font-size: var(--normal-font-size);
                    border-top: 1px solid var(--border-color);
                    border-right: 1px solid var(--border-color);
                    background-color: var(--background-color-white);

                    .name-column {
                        min-height: 2.5rem;
                        border-bottom: 1px solid var(--border-color);

                        &.show-resource-group-header-div {
                            min-height: calc(4rem + 1px);
                        }
                    }

                    .day-column {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        border-bottom: 1px solid var(--border-color);
                        position: relative;
                        padding-left: 1rem;
                        
                        .date-name {
                            position: absolute;
                            top: 50%;
                            left: 1rem;
                            transform: rotate(-90deg) translate(-40%, 0);
                            transform-origin: left center;
                            white-space: nowrap;   
                        }

                        .today {
                            position: relative;
                        }
        
                        .today::after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -4.5px;
                            left: 0;
                            right: 1px;
                            height: 0.2rem;
                            border-radius: 0.2rem;
                            background-color: var(--primary-color);
                        }

                        .header-hours {
                            margin-top: -1.3rem;
                            margin-right: -0.85rem;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: space-between;
                            box-sizing: border-box;
                            height: 100%;
                            font-family: var(--header-font-light);
                            font-size: var(--smaller-font-size);
                            color: var(--text-color-lightgray);
    
                            .header-hour {
                                flex: 1;
                                display: flex;
                                justify-content: center;
                                box-sizing: border-box;
                                margin-top: 2.5px;
                            }
    
                            .invisible {
                                visibility: hidden;
                            }
                        }
                    }
                }

                .resource-group-header {
                    min-height: 1.5rem;
                    background-color: var(--background-color-white);
                    font-family: var(--header-font-regular);
                    font-size: var(--small-font-size);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    border-top: 1px solid var(--border-color);

                    &.border {
                        border-left: 1px solid var(--border-color);
                    }
                }

                .resources {
                    display: grid;
                    grid-auto-flow: column;
                    box-sizing: border-box;

                    .resource-row {
                        border-left: 1px solid var(--border-color);

                        &.no-border-left {
                            border-left: none;
                        }
                    }

                    .unassigned-row, .resource-row {
                        display: flex;
                        flex-direction: column;
                        background-color: var(--background-color-white);
                        border-top: 1px solid var(--border-color);
                        border-bottom: 1px solid var(--border-color);

                        .name-column {
                            min-height: 2.5rem;
                            background-color: var(--background-color-white);
                            font-family: var(--header-font-medium);
                            font-size: var(--normal-font-size);
                            padding-left: 0.5rem;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;

                            .resource-image {
                                order: 3;
                                overflow: hidden;
                                background-size: cover;
                                background-position: center;
                                position: relative;
                                height: 2rem;
                                width: 2rem;
                                border-radius: 50%;
                                margin-left: auto;
                                margin-right: 0.5rem;
                            }

                            .resource-details {
                                order: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: start;
                                flex-grow: 1;
                                flex-shrink: 1;
                
                                .remark {
                                    font-family: var(--main-font-regular);
                                    color: var(--primary-text-color);
                                    font-size: var(--small-font-size);
                                }
                            }

                            .order-icon {
                                order: 2;
                                margin-right: 0.25rem;
                            }
                        }

                        .day-column {
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                            height: 100%;
                            background-color: var(--background-color);
                            border-top: 1px solid var(--border-color);

                            .day-list {
                                position: relative;
                                height: 100%;
                                padding: 0 0.25rem 0 0.25rem;

                                .event {
                                    position: relative;
                                    margin-top: 0.25rem;
                                    height: 1.25rem;
                                    padding: 0.25rem;
                                }
                            }

                            .time-slot {
                                position: relative;
                                overflow: visible;
                                flex: 1 1 auto;
                                box-sizing: border-box;

                                .tooltip {
                                    position: absolute;
                                    top: -25px;
                                    left: 0;
                                    z-index: 9999 !important;
                                }
                
                                &.whole-hour {
                                    border-top: 1px solid var(--border-color-light);
                                }
                
                                &:hover {
                                    background-color: var(--secondary-color);
                                }

                                &.no-hover {
                                    pointer-events: none;
                                    background-color: transparent !important;
                                }

                                &.has-event:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }   
        }
    }    
}


.job-inbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid var(--border-color);
    background-color: var(--background-color-white);
    z-index: 2;

    > .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        background-color: var(--background-color-white);
        height: 2.5rem;
        padding-left: 0.5rem;

        .title {
            align-items: center;
            font-family: var(--header-font-medium);
            font-size: var(--normal-font-size);
        }

        .help {
            display: flex;
            align-items: center;
            padding-left: 0.3rem;
            padding-top: 0.15rem;
        }

        .icons {
            margin-left: auto;
            display: flex;
            gap: 0.5rem;

            .icon {
                color: var(--text-color-lightgray);
                font-size: var(--normal-font-size);
                cursor: pointer;
            }

            .icon:last-child {
                margin-right: 0.5rem;
            }
        }
    }

    .inbox {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        max-height: calc(100vh - 2.5rem);

        .inbox-loader {
            display: flex;
            justify-content: center;
            padding: 1rem;
            align-items: center;
        }

        .inbox-rows {
            .inbox-row {
                .header {
                    text-align: center;
                    position: relative;
                    border-top: 1px solid var(--border-color);
                    border-bottom: 1px solid var(--border-color);
                    background-color: var(--background-color-white);
    
                    &:first-of-type {
                        margin-top: -1px;
                    }
    
                    .label, .budget {
                        font-family: var(--header-font-regular);
                        font-size: var(--small-font-size);
                        color: var(--tertiair-text-color);
                    }
    
                    .label {
                        text-align: center;
                    }
                    
                    .budget {
                        position: absolute;
                        top: 0.15rem;
                        right: 0.25rem;
                    }
                }
    
                .job-inbox-item {
                    background-color: var(--background-color-white);
                    border-bottom: 1px solid var(--border-color);
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
                    padding: 0.5rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--list-hover-color);
                    }
            
                    .info {
                        .title {
                            font-family: var(--main-font-regular);
                            font-size: var(--small-font-size);
                            color: var(--primary-text-color);

                            .status-badge {
                                font-family: var(--header-font-regular);
                                font-size: var(--smaller-font-size);
                                padding-left: 0.2rem;
                                padding-right: 0.2rem;
                                margin-left: 0.3rem;
                            }
                        }
            
                        .remark {
                            font-family: var(--main-font-regular);
                            color: var(--text-color-lightgray);
                            font-size: var(--small-font-size);
                        }
                    }
            
                    .order-icon {
                        color: var(--text-color-lightgray);
                        font-size: 0.85rem;
            
                        &:hover {
                            cursor: grab;
                        }
            
                        &:active {
                            cursor: grabbing;
                        }
                    }
                }
    
                .row-space {
                    height: 1rem;
                }
            }
        }

        .scroll-text {
            position: fixed;
            bottom: 0rem;
            right: 0rem;
            padding: 0.25rem;
            text-align: center;
            font-family: var(--main-font-light);
            font-size: var(--smaller-font-size);
            color: var(--text-color-lightgray);
            background-color: var(--background-color);
            z-index: 2;
    
            .scroll-text-button {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}