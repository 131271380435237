/* navigation.css */

@import './global-variables.scss';

.topbar {
    background-color: var(--general-yellow);
    font-family: var(--header-font-regular);
    color: var(--primary-button-text-color);
    font-size: var(--small-font-size);
    text-align: center;
    padding: 0.25rem;

    &.subtile {
        background-color: var(--background-color-darkest);
    }

    &.warning {
        background-color: var(--general-yellow);
    }

    &.danger {
        background-color: var(--primary-color);
    }

    .link:hover {
        color: var(--menu-hover-color);
        cursor: pointer;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navbar-color);
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.logo-container {
    height: 2.5rem;
}

.logo {
    align-items: center;
    height: 2.5rem;
}

.main-menu-items {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.main-menu-items .navbar-item {
    padding: 0 0.8rem;
}

.navbar-item {
    cursor: pointer;
    font-family: var(--header-font-medium);
    color: var(--primary-button-text-color);
    font-size: var(--larger-font-size);
    background-color: transparent;
    border: none;
}

.navbar-item.active,
.navbar-item:hover {
    color: var(--menu-hover-color);
}

.navbar .right-menu-items {
    display: flex;
    align-items: center;
}

.navbar .profile-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 15px;
}

.navbar .profile-image {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.navbar .profile-container:hover {
    cursor: pointer;
}

.navbar .profile-container:hover .profile-image {
    opacity: 0.5;
}

.navbar .profile-container:hover .navbar-item {
    color: var(--menu-hover-color);
}

.navbar {
    .dropdown-menu {
        position: absolute;
        top: 3.5rem;
        margin: 0.5rem 0 0 0.5rem;
        right: 1rem;
        z-index: 3;
        display: block;
        background-color: var(--background-color-white);
        border-radius: 0.5rem;
        box-shadow: 0 0 20px rgba(10, 10, 10, 0.25);

        &.topbar-enabled {
            top: 4.8rem;
        }

        .dropdown-item {
            font-family: var(--header-font-medium);
            font-size: var(--normal-font-size);
            color: var(--secondary-button-text-color);
            align-items: center;
            min-height: 2.5rem;
            display: flex;
            white-space: nowrap;
            padding: 0 1rem;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid var(--border-color);
            }

            &:hover {
                background-color: var(--list-hover-color);
                color: var(--primary-color);
            }

            &:first-child:hover {
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }

            &:last-child:hover {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
        }
    }
}

.subnavbar {
    background-color: var(--background-color-white);
    border-bottom: 1px solid var(--border-color);
}

.subnav-container {
    display: flex;
    justify-content: flex-start;
}

.subnav-item {
    cursor: pointer;
    font-family: var(--header-font-medium);
    color: var(--primary-text-color);
    font-size: var(--normal-font-size);
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.subnav-item:hover, .subnav-item.active {
    color: var(--primary-color);
}

/* Removes underline from a links */
a:-webkit-any-link {
    text-decoration: unset;
}

/* Sidebar styling */
.sidebar-nav-group {
    margin-bottom: 1.75rem;
}

.sidebar-nav-group h4 {
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
}

.sidebar-nav-item {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.5rem;
    cursor: pointer;
    align-items: center;
}

.sidebar-nav-item:hover {
    background-color: var(--secondary-color);
    border-radius: 0.3rem;
}

.sidebar-nav-item:hover .sidebar-nav-link {
    color: var(--primary-text-color);
    font-family: var(--header-font-medium);
}

.sidebar-nav-item.active {
    background-color: var(--secondary-color);
    border-radius: 0.3rem;
}

.sidebar-nav-item.active .sidebar-nav-link {
    color: var(--primary-text-color);
    font-family: var(--header-font-medium);
}

.sidebar-nav-link {
    font-size: var(--larger-font-size);
    font-family: var(--header-font-regular);
    color: var(--secondary-button-text-color);
}